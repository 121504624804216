/**
 * Parametr
 * @param {number} id
 * @param {Object} data - json data
 * @param {string} html - html obsah parametru
 * @constructor
 */
function Parameter(id, data, html) {

    Base.apply(this, arguments);

    /**
     * Vzorec vypoctu
     * @type {string}
     */
    this.calculation = data.calculation;

    /**
     * Minimalni hodnota
     * @type {number}
     */
    this.min = data.min;

    /**
     * Maximalni hodnota
     * @type {number}
     */
    this.max = data.max;

    /**
     * Typ zobrazeni
     * @type {number}
     */
    this.typeDisplay = data.typeDisplay;

    /**
     * Id parametru
     * @type {number}
     */
    this.parameter = data.parameter;

}

Parameter.prototype = Base.prototype;
//Parameter.prototype.constructor = Parameter;

/**
 * Vrati vzorec vypoctu
 * @returns {string}
 */
Parameter.prototype.getCalculation = function () {
    return this.calculation;
};

/**
 * Vrati minimalni hodnotu
 * @returns {number}
 */
Parameter.prototype.getMin = function () {
    return this.min;
};

/**
 * Vrati maximalni hodnotu
 * @returns {number}
 */
Parameter.prototype.getMax = function () {
    return this.max;
};

/**
 * Vrati typ zobrazeni
 * @returns {number}
 */
Parameter.prototype.getTypeDisplay = function () {
    return this.typeDisplay;
};

/**
 * Vrati id parametru
 * @returns {number}
 */
Parameter.prototype.getParameter = function () {
    return this.parameter;
};
