var activeRows = new Array();
var gridId = null;

$.eachComponent('.dataGrid', function(grid) {

    // Načtení vlastností z html atributu
    var options = $.parseJSON($(grid).attr('data-datagrid-options'));

    // DblClick nad řádky, 
    if(options.dblClick) {
        $(grid).on('dblclick', '.dataGrid-body-row', function() {
            $(".dataGrid-body-button" + options.dblClick, this).click();
        });
    }
    
    $(".dataGrid-body-button").click(function() { 
        //načtu id gridu, kde jsem klikl
        gridId = $(this).closest(".dataGrid").closest("div").attr("id");
        //odděláme podbarvení u starého řádku
        $(this).closest(".dataGrid").find(".dataGrid-body-row .activeRow").removeClass("activeRow");
        //načtem id aktuálního řádku
        activeRows[gridId] = $(this).closest(".dataGrid-body-row").attr("id");
        //a přidáme třídu activeRow
        $('#'+activeRows[gridId]).children().addClass("activeRow");
        return true;
    }); 
    
    $.eachComponent(".dataGrid", function(grid) {
        //načti id gridu, co se překreslil
        gridId = $(grid).closest("div").attr("id");
        if (typeof activeRows[gridId] !== 'undefined' && activeRows[gridId] !== null) {
            $('#'+activeRows[gridId]).children().addClass("activeRow");
            activeRows[gridId] = null; 
        }
    });

    // Drag&Drop řazení řádků tabulky
    var helperTable = $(grid).clone();
    $('thead, tbody', helperTable).remove();
    helperTable.append($('<tbody/>'));
    $('tbody', grid).sortable({
        items: "tr",
        opacity: 0.85,
        placeholder: "datagrid-sortable-placeholder",
        revert: true,
        helper: function(e, row) {
            return helperTable.clone().append($(row).clone());
        },
        handle: "td.datagrid-tool-sort",
        update: function(e, ui) {
            var position = $('tbody tr', grid).index(ui.item)+1;
            var href = $('.datagrid-tool-sort [data-href]', ui.item).data('href').replace(/position=[0-9]+/, 'position=' + position);
            $.application.ajax(href);
        },
        sort: function(e, ui) {            
            ui.placeholder.height(ui.helper.height());
        }
    });
    $('td.datagrid-tool-sort', grid).disableSelection();
    
    $(".datagrid-tool-set-default", grid).live('click', function() {
        if($('input', this).is(':checked')) {
            $.application.ajax($('input', this).data('href'));
        }
    }); 

});

 /*$.eachComponent('.grid-checkbox', function(check){
        $(check).change(function(){
            $.application.ajax($(check).attr('data-signal'),{},false)
        });
    });*/