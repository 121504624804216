/**
 * JS validace formulářových prvků
 * @author Martin Vágovszký
 */
(function ($) {
    $.extend({
        validator: {
            msg: "",
            init: function (element) {
                var validator = $.validator;
                $(element).on('keyup', validator.onChange);
                $(element).on('change', validator.onChange);
                $(element).on('focus', validator.onFocus);
                $(element).on('blur', validator.onBlur);
            },
            onFocus: function (event) {
                $.validator.test(this);
            },
            onChange: function (event) {
                $.validator.test(this);
            },
            onBlur: function () {
                $.validator.hideArrowMsg();
            },
            test: function (element) {
                if ($(element).is(':visible') && !$.validator.validateControl(element)) {
                    // chyba
                    $.validator.highlightError(element);
                    $.validator.showArrowMsg(element);
                } else {
                    // Neni chyba
                    $.validator.disableHighlightError(element);
                    $.validator.hideArrowMsg();
                    $.validator.showDescriptionIfExist(element);
                }
            },
            showDescriptionIfExist: function (element) {
                if ($(element).parents('.pair').find('.arrowmess.description').size() > 0) {
                    $.validator.msg = $('.arrowmess-body > div', $(element).parents('.pair')).html();
                    $.validator.showArrowMsg(element, 'description');
                }
            },
            showArrowMsg: function (element, type) {
                var t_type = type || 'error';
                $.application.arrowMessage.show(element, t_type, $.validator.msg);
            },
            hideArrowMsg: function () {
                $.application.arrowMessage.hide();
            },
            highlightError: function (element) {
                $(element).addClass('error-highlight');
            },
            disableHighlightError: function (element) {
                if ($(element).hasClass('error-highlight')) {
                    $(element).removeClass('error-highlight');
                }
            },
            validateControl: function (elem, rules, onlyCheck) {
                rules = rules || eval(elem.getAttribute('data-nette-rules') || '[]');
                for (var id = 0, len = rules.length; id < len; id++) {
                    var rule = rules[id];
                    var op = rule.op.match(/(~)?([^?]+)/);
                    rule.neg = op[1];
                    rule.op = op[2];
                    rule.condition = !!rule.rules;
                    var el = rule.control ? elem.form.elements[rule.control] : elem;

                    if (el) {
                        var success = $.validator.validateRule(el, rule.op, rule.arg);
                        if (success === null) {
                            continue;
                        }
                        if (rule.neg) {
                            success = !success;
                        }

                        if (rule.condition && success) {
                            if (!$.validator.validateControl(elem, rule.rules, onlyCheck)) {
                                return false;
                            }
                        } else if (!rule.condition && !success) {
                            if (el.disabled) {
                                continue;
                            }
                            if (!onlyCheck) {
                                $.validator.msg = rule.msg.replace('%value', $(el).val());
                                return false;
                            }
                            return false;
                        }
                    }
                }
                return true;
            },
            validateRule: function (elem, op, arg) {
                var val = $(elem).val();

                if (elem.getAttribute) {
                    if (val === elem.getAttribute('data-nette-empty-value')) {
                        val = '';
                    }
                }
                if (op.charAt(0) === ':') {
                    op = op.substr(1);
                }
                op = op.replace('::', '_');
                op = op.replace(/\\/g, '');
                return $.validator.validators[op] ? $.validator.validators[op](elem, arg, val) : null;
            },
            validators: {
                filled: function (elem, arg, val) {
                    return val !== '' && val !== false && val !== null;
                },
                valid: function (elem, arg, val) {
                    return $.validator.validateControl(elem, null, true);
                },
                equal: function (elem, arg, val) {
                    if (arg === undefined) {
                        return null;
                    }
                    arg = isArray(arg) ? arg : [arg];
                    for (var i = 0, len = arg.length; i < len; i++) {
                        if (val == (arg[i].control ? $(elem.form.elements[arg[i].control]).val() : arg[i])) {
                            return true;
                        }
                    }
                    return false;
                },
                minLength: function (elem, arg, val) {
                    return val.length >= arg;
                },
                maxLength: function (elem, arg, val) {
                    return val.length <= arg;
                },
                length: function (elem, arg, val) {
                    arg = isArray(arg) ? arg : [arg, arg];
                    return (arg[0] === null || val.length >= arg[0]) && (arg[1] === null || val.length <= arg[1]);
                },
                email: function (elem, arg, val) {
                    return (/^[^@\s]+@[^@\s]+\.[a-z]{2,10}$/i).test(val);
                },
                url: function (elem, arg, val) {
                    return (/^.+\.[a-z]{2,6}(\/.*)?$/i).test(val);
                },
                regexp: function (elem, arg, val) {
                    var parts = typeof arg === 'string' ? arg.match(/^\/(.*)\/([imu]*)$/) : false;
                    if (parts) {
                        try {
                            return (new RegExp(parts[1], parts[2].replace('u', ''))).test(val);
                        } catch (e) {
                        }
                    }
                },
                pattern: function (elem, arg, val) {
                    try {
                        return typeof arg === 'string' ? (new RegExp('^(' + arg + ')$')).test(val) : null;
                    } catch (e) {
                    }
                },
                integer: function (elem, arg, val) {
                    return (/^-?[0-9]+$/).test(val);
                },
                float: function (elem, arg, val) {
                    return (/^-?[0-9]*[.,]?[0-9]+$/).test(val);
                },
                range: function (elem, arg, val) {
                    return isArray(arg) ? ((arg[0] === null || parseFloat(val) >= arg[0]) && (arg[1] === null || parseFloat(val) <= arg[1])) : null;
                },
                SynopsisFormsValidators_validateIN: function(elem, arg, val) {
                    var matches = val.match(/^\s*(\d\d)(\d\d)(\d\d)[ \/]*(\d\d\d)(\d?)\s*$/);
                    if (!matches) {
                        return false;
                    }

                    var year = parseInt(matches[1]);
                    var month = parseInt(matches[2]);
                    var day = parseInt(matches[3]);
                    var ext = parseInt(matches[4]);
                    var c = parseInt(matches[5]);

                    // do roku 1954 přidělovaná devítimístná RČ nelze ověřit
                    if (isNaN(c)) {
                        return year < 54;
                    }

                    // kontrolní číslice
                    var mod = parseInt(matches[1] + matches[2] + matches[3] + matches[4]) % 11;
                    if (mod == 10) {
                        mod = 0;
                    }
                    if (mod != c) {
                        return false;
                    }

                    // kontrola data
                    year += year < 54 ? 2000 : 1900;

                    // k měsíci může být připočteno 20, 50 nebo 70
                    if (month > 70 && year > 2003) {
                        month -= 70;
                    } else if (month > 50) {
                        month -= 50;
                    } else if (month > 20 && year > 2003) {
                        month -= 20;
                    }

                    var date = new Date(year, month - 1, day);
                    if (date.getFullYear() != year || date.getMonth() + 1 != month || date.getDate() != day) {
                        return false;
                    }

                    // cislo je OK
                    return true;
                },
                SynopsisFormsValidators_validateCN: function (elem, arg, val) {
                    // "be liberal in what you receive"
                    var cn = val.replace('\s+', '');

                    // má požadovaný tvar?
                    if (!(/^\d{8}$/).test(cn)) {
                        return false;
                    }

                    // kontrolní součet
                    var a = 0;
                    for (var i = 0; i < 7; i++) {
                        a += cn[i] * (8 - i);
                    }

                    a = a % 11;

                    var c = 11 - a;
                    if (a === 0) {
                        c = 1;
                    }
                    else if (a === 10) {
                        c = 1;
                    }
                    else if (a === 1) {
                        c = 0;
                    }

                    return cn[7] == c;
                },
                SynopsisFormsValidators_validateZIPCODE: function (elem, arg, val) {
                    val = val.replace(/\s/g, '');
                    try {
                        return typeof arg === 'string' ? (new RegExp('^(' + arg + ')$')).test(val) : null;
                    } catch (e) {
                    }
                },
                SynopsisFormsValidators_validateMinNumbers: function (elem, arg, val) {
                    return val.replace(/\D/g, '').length >= arg;
                },
                SynopsisFormsValidators_validateMinLetters: function (elem, arg, val) {
                    return val.replace(/\d/g, '').length >= arg;
                }
            }
        }
    });
})(jQuery);

// Aktivuje validatory
$.eachComponent('form :input:not(:disabled), form .input:not(:disabled)', function (element) {
    $.validator.init(element);
});


