/**
 * Zakladni prvek pruvodce
 * @param id
 * @param data
 * @param html
 * @constructor
 */
function Base(id, data, html) {

    /**
     * Id
     * @type {number}
     */
    this.id = id;

    /**
     * Podminka zobrazeni
     * @type {string}
     */
    this.condition = data.condition;

    /**
     * Html obsah volby
     * @type {string}
     */
    this.html = html;

}

/**
 * Vrati id
 * @returns {number}
 */
Base.prototype.getId = function () {
    return this.id;
};

/**
 * Vrati podminku zobrazeni
 * @returns {string}
 */
Base.prototype.getCondition = function () {
    return this.condition;
};

/**
 * Vrati html parametru
 * @returns {string}
 */
Base.prototype.getHtml = function() {
    return this.html;
};

/**
 * Vrati input prvku
 * @returns {HTMLElement}
 */
Base.prototype.getInput = function () {
    return $('input, select, textarea', this.html);
};

/**
 * Kontrola podminky zobrazeni
 * @param {string} condition
 * @param {Array} variables
 * @returns {boolean}
 */
function checkCondition(condition, variables) {
    var result = true;

    if (condition !== '') {
        var formula = new Formula(prepareFormula(condition, variables), variables);
        formula.setSymbols(Condition.getSymbols());
        result = formula.evaluate();
    }

    return result;
}