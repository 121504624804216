function isArray(obj) {
    if (typeof obj != "undefined" && obj != null) {
        return obj.constructor == Array;
    }
    return false;
}

function countProps(obj) {
    var l = 0;
    for (p in obj) {
        l++;
    }
    return l;
}

/**
 * Zacyklí js na ms milisekund
 */
function sleep(ms) {
    var dt = new Date();
    dt.setTime(dt.getTime() + ms);
    while (new Date().getTime() < dt.getTime()) {}
}

function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search) || [, ""])[1].replace(/\+/g, '%20')) || null;
}

function addOrUpdateUrlParam(name, value) {
    var href = window.location.href;
    var regex = new RegExp("[&\\?]" + name + "=");
    if (regex.test(href)) {
        regex = new RegExp("([&\\?])" + name + "=\\d+");
        window.location.href = href.replace(regex, "$1" + name + "=" + value);
    } else {
        if (href.indexOf("?") > -1) {
            window.location.href = href + "&" + name + "=" + value;
        } else {
            window.location.href = href + "?" + name + "=" + value;
        }
    }
}

function removeUrlParam(key) {
    var href = window.location.href;
    var rtn = href.split("?")[0];
    var param = '';
    var params_arr = [];
    var queryString = (href.indexOf("?") !== -1) ? href.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
}