Formula.round = function(number, num_digits) {
    if(typeof num_digits === 'undefined') {
        num_digits = 0;
    }

    return number.toFixed(num_digits);
};

Formula.roundup = function(number, num_digits) {
    if(typeof num_digits === 'undefined') {
        return Math.ceil(number);
    }
    var decimal = Math.pow(10, (-1 * num_digits));

    return Math.ceil(number/decimal) * decimal;
};

Formula.rounddown = function(number, num_digits) {
    if(typeof num_digits === 'undefined') {
        return Math.floor(number);
    }
    var decimal = Math.pow(10, (-1 * num_digits));

    return Math.floor(number/decimal) * decimal;
};

Formula.min = function(a, b) {
    return Math.min(a, b);
};

Formula.max = function(a, b) {
    return Math.max(a, b);
};

Formula.sqrt = function(number) {
    return Math.sqrt(number);
};

Formula.sin = function($number) {
    return Math.sin($number);
};

Formula.cos = function($number) {
    return Math.cos($number);
};

function Condition() {}

/**
 * Symboly pro podminky
 * @returns {{mult: {label: string, value: string}, add: {label: string, value: string}, sub: {label: string, value: string}, div: {label: string, value: string}, lparen: {label: string, value: string}, rparen: {label: string, value: string}, eq: {label: string, value: string}, lt: {label: string, value: string}, gt: {label: string, value: string}, lte: {label: string, value: string}, gte: {label: string, value: string}, ne: {label: string, value: string}, and: {label: string, value: string}, or: {label: string, value: string}, true: {label: string, value: string}, false: {label: string, value: string}, null: {label: string, value: string}, not: {label: string, value: string}}}
 */
Condition.getSymbols = function() {
    return {
        mult: {label: 'x', value: '*'},
        add: {label: '+', value: '+'},
        sub: {label: '-', value: '-'},
        div: {label: '/', value: '/'},
        lparen: {label: '(', value: '('},
        rparen: {label: ')', value: ')'},
        eq: {label: '=', value: '==='},
        lt: {label: '<', value: '<'},
        gt: {label: '>', value: '>'},
        lte: {label: '<=', value: '<='},
        gte: {label: '>=', value: '>='},
        ne: {label: '<>', value: '!=='},
        and: {label: 'A', value: '&&'},
        or: {label: 'Nebo', value: '||'},
        "true": {label: 'Ano', value: 'true'},
        "false": {label: 'Ne', value: 'false'},
        "null": {label: 'Null', value: 'undefined'},
        not: {label: 'Not', value: '!'}
    };
};

function Calculation() {}

/**
 * Symboly pro vypocty
 * @returns {{mult: {label: string, value: string}, add: {label: string, value: string}, sub: {label: string, value: string}, div: {label: string, value: string}, lparen: {label: string, value: string}, rparen: {label: string, value: string}, round: {label: string, value: string}, roundup: {label: string, value: string}, rounddown: {label: string, value: string}, min: {label: string, value: string}, max: {label: string, value: string}, sqrt: {label: string, value: string}, sin: {label: string, value: string}, cos: {label: string, value: string}}}
 */
Calculation.getSymbols = function() {
    return {
        mult: {label: 'x', value: '*'},
        add: {label: '+', value: '+'},
        sub: {label: '-', value: '-'},
        div: {label: '/', value: '/'},
        lparen: {label: '(', value: '('},
        rparen: {label: ')', value: ')'},
        "round": {label: 'Round', value: 'Formula.round'},
        "roundup": {label: 'RoundUp', value: 'Formula.roundup'},
        "rounddown": {label: 'RoundDown', value: 'Formula.rounddown'},
        "min": {label: 'Min', value: 'Formula.min'},
        "max": {label: 'Max', value: 'Formula.max'},
        "sqrt": {label: '√', value: 'Formula.sqrt'},
        "sin": {label: 'sin', value: 'Formula.sin'},
        "cos": {label: 'cos', value: 'Formula.cos'}
    };
};