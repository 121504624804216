

/**
 * String.reverse()
 */
String.prototype.reverse = function() {
    return this.split('').reverse().join('');
};


/**
 * String.toNumber()
 */
String.prototype.toNumber = function() {
    var str = this.replace(/[\s]/g, '');
    var delim = str.match(/[.,]/g);
    if(delim && delim.length > 1) {
        console.warn("String.prototype.toNumber: Not implemented");
        str = "0";
    }
    else {
        str = str.replace(/[.,]/g, '.');
    }
    return parseFloat(str);
};

String.prototype.replaceArray = function(find, replace) {
    var replaceString = this.toString();
    var regex; 
    for (var i = 0; i < find.length; i++) {
        regex = new RegExp(find[i], "g");
        replaceString = replaceString.replace(regex, replace[i]);
    }
    return replaceString;
};

String.prototype.replaceCallback = function(searchvalue, callback) {
    var position = 0;
    var fromStr = this.toString();
    var toStr = "";
    var match = "";
    var pom = "";
    while((position = fromStr.search(searchvalue)) !== -1) {
        // oříznutí začátku
        toStr += fromStr.substring(0, position);
        fromStr = fromStr.substring(position);
        // najití řetězce a jeho nahrazení callback funkcí
        pom = fromStr.match(searchvalue);
        match = fromStr.match(searchvalue)[0];
        toStr += callback(match);
        fromStr = fromStr.substring(match.length);
    }
    toStr += fromStr;

    return toStr;
};

if(!String.prototype.trim){
    String.prototype.trim = function() {
        return this.replace(/^\s+|\s+$/g, '');
    };
}

if(!String.prototype.ltrim){
    String.prototype.ltrim = function() {
        return this.replace(/^\s+/,'');
    };
}

if(!String.prototype.rtrim){
    String.prototype.rtrim = function() {
        return this.replace(/\s+$/,'');
    };
}

/**
 * Number.format(decimals = 0, dec_point = '.' , thousands_sep = ',')
 */
Number.prototype.format = function(decimals, dec_point , thousands_sep) {
    decimals = decimals != undefined ? decimals : 0;
    dec_point = dec_point != undefined ? dec_point : '.';
    thousands_sep = thousands_sep != undefined ? thousands_sep : ',';

    var num;

    if(isNaN(this)) {
        num = (new Number(0)).toFixed(decimals);
    }
    else {
        num = this.toFixed(decimals);
    }

    num = num.split(/[\.,]/);
    num[0] = num[0].reverse().match(/.{1,3}/g).join(thousands_sep).reverse();

    return num.join(dec_point);
};


/**
 * Metoda indexOf (především pro IE)
 * Vrací index prvku v poli nebo -1 pokud nic nenajde
 */
if (!Array.prototype.indexOf) {

    Array.prototype.indexOf = function(obj, start) {
        var i = (start || 0), j = this.length;
        for (; i < j; i++) {
            if (this[i] === obj) { return i; }
        }
        return -1;
    };

}


/**
 * Procházení polem
 * Narozdíl od klasického for cyklu vynechává všechny metody definované přes Array.prototype
 */
Array.prototype.each = function(callback) {
    for(var i in this) {
        if(Array.prototype[i] === undefined) {
            if(callback) {
                callback(i, this[i]);
            }
        }
    }
};


/**
 * TEST
 */
/*
fireunit.ok(isNaN("test".toNumber()), "test");

fireunit.compare("123456789".toNumber(), 123456789, "123456789");
fireunit.compare("123456.789".toNumber(), 123456.789, "123456.789");

fireunit.compare("123 456789".toNumber(), 123456789, "123 456789");
fireunit.compare("123 456.789".toNumber(), 123456.789, "123 456.789");

fireunit.compare("123456,789".toNumber(), 123456.789, "123456,789");
fireunit.compare("123 456,789".toNumber(), 123456.789, "123 456,789");

fireunit.compare("123,456.789".toNumber(), 123456.789, "123,456.789");
fireunit.compare("123.456,789".toNumber(), 123456.789, "123.456,789");

fireunit.compare("123.456.789".toNumber(), 123456.789, "123.456.789");
fireunit.compare("123,456,789".toNumber(), 123456.789, "123,456,789");

fireunit.testDone();
*/