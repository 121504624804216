$.eachComponent('#nav', function(context) {
    $('#nav-login', context).live('click', function(){
        $('.sign-in-form', context).fadeIn("slow");
        $('#currencypanel').hide();
        $('#langpanel').hide();
    });
    
    $('.sign-in-form .exit', context).live('click', function() {
        $('.sign-in-form', context).fadeOut("slow");
    });

    $('#nav-marker-history', context).live('click', function() {
        if($('.nav-history', context).is(':hidden')) {
            $('.nav-history', context).fadeIn("slow");
        }
        else {
            $('.nav-history', context).fadeOut("slow");
        }
        
        $('.nav-history > a > .item', context).mouseenter(function() {
            $(this).children('.caption').addClass('hover');
        });
        
        $('.nav-history > a > .item', context).mouseleave(function() {
            $(this).children('.caption').removeClass('hover');
        });
    }); 
    
    $(context).on('click','#nav-marker-history, #nav-login',function(event){
        event.stopPropagation();
    });
});

/*$.eachComponent('.sign-in-form input[name="email"], .sign-in-form input[name="password"]', function(context) {
    console.log($(context).hasClass('error-highlight'));
    if($(context).hasClass('error-highlight')) {
        
    }
    else {
        $('.sign-in-form', context).fadeOut("slow");
    }
});*/

$.eachComponent('.sign-in', function(context) {
    if(!$('input[name="email"], input[name="password"]', context).hasClass('error-highlight')) {
        $('.sign-in-form').fadeOut("slow");
    }
});

$.eachComponent('.list', function(context) {
    $('.list-button', context).live('click', function() {
        if($('.nav-history', context).is(':hidden')) {
            $('.nav-history', context).fadeIn("slow");
        }
        else {
            $('.nav-history', context).fadeOut("slow");
        }
        
        $('.nav-history > a > .item', context).mouseenter(function() {
            $(this).children('.caption').addClass('hover');
        });
        
        $('.nav-history > a > .item', context).mouseleave(function() {
            $(this).children('.caption').removeClass('hover');
        });
    }); 
    
    $(context).on('click','.list-button',function(event){
        event.stopPropagation();
    });
});

$().ready(function() {
    $('html').bind('click contextmenu', function(){
        $('.nav-history').fadeOut("slow");
        $('.links').fadeOut("slow");
        //$('.sign-in-form').fadeOut("slow");
    });
});


