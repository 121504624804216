/**
 * Volba rozcestniku
 * @param {number} id - id
 * @param {Object} data - json data
 * @param {string} html - html obsah volby
 * @constructor
 */
function Option(id, data, html) {

    Base.apply(this, arguments);

    /**
     * Id nasledujici karty
     * @type {string}
     */
    this.next = data.next;

    /**
     * Parametry
     * @type {OptionParameter[]}
     */
    this.parameters = [];

    /**
     * @param {OptionParameter} parameter
     */
    this.addParameter = function (parameter) {
        this.parameters[parameter.getId()] = parameter;
    };

    var option = this;
    $.each(data.parameters, function(id, data) {
        option.addParameter(new OptionParameter(id, data));
    });
}

Option.prototype = Base.prototype;
//Option.prototype.constructor = Option;

/**
 * Vrati id nasledujici karty
 * @returns {string}
 */
Option.prototype.getNext = function() {
    return this.next;
};

/**
 * Vrati parametry volby
 * @returns {Array}
 */
Option.prototype.getParameters = function() {
    return this.parameters;
};

/**
 * Parametr volby rozcestniku
 * @param id
 * @param data
 * @constructor
 */
function OptionParameter(id, data) {

    this.TYPE_VALUE = 1;
    this.TYPE_SELECTION = 2;
    this.TYPE_VALUE_RANGE = 3;
    this.TYPE_VARIABLE = 4;
    this.TYPE_CALCULATION = 5;
    this.TYPE_YES_NO = 6;
    this.TYPE_TEXT = 7;

    /**
     * Id
     * @type {number}
     */
    this.id = id;

    /**
     * Typ
     * @type {number}
     */
    this.type = data.type;

    /**
     * Hodnota
     * @type {string}
     */
    this.value = data.value;

    /**
     * Nazev
     * @type {string}
     */
    this.name = data.name;

    /**
     * Vzorec
     * @type {string}
     */
    this.calculation = data.calculation;

    /**
     * Typ zobrazeni
     * @type {number}
     */
    this.typeDisplay = data.typeDisplay;
}

/**
 * Vrati id
 * @returns {number}
 */
OptionParameter.prototype.getId = function() {
    return this.id;
};

/**
 * Vrati typ
 * @returns {number}
 */
OptionParameter.prototype.getType = function() {
    return this.type;
};

/**
 * Vrati hodnotu
 * @returns {string}
 */
OptionParameter.prototype.getValue = function() {
    return this.value;
};

/**
 * Vrati nazev
 * @returns {string}
 */
OptionParameter.prototype.getName = function() {
    return this.name;
};

/**
 * Vrati vzorec
 * @returns {string}
 */
OptionParameter.prototype.getCalculation = function() {
    return this.calculation;
};

/**
 * Vrati tyo zobrazeni
 * @returns {number}
 */
OptionParameter.prototype.getTypeDisplay = function() {
    return this.typeDisplay;
};