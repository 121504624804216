// ma se kliknout na dalsi kartu po nacteni vsech dosazitelnych?
var clickNext = false;
var xhrs = [];

/**
 * Nacteni nasledujicich karet
 * @param {Card} card
 * @param {HTMLElement} divCard
 */
function loadNextCards(card, divCard) {
    var nexts = card.getNexts();
    var requests = [];

    for (var i = 0; i < nexts.length; i++) {
        if (!$.application.cards.getById(nexts[i]) && (typeof $.application.xhrs[nexts[i]] === 'undefined')) {
            requests.push($.application.ajaxLoadCard($('.url-load-card').data('href'), nexts[i], false));
        }
    }

    // pockame az se vsechny nasledujici karty nactou
    if (requests.length > 0) {
        $.when.apply($, requests).done(function () {
            $('.step_menu .button .next', divCard).removeClass('loading');
            if (clickNext) {
                $('.step_menu .button .next', divCard).click();
                clickNext = false;
            }
        });
    }
}

/**
 * Najde nasledujici kartu dle podminek zobrazeni
 * @param {Card} card
 * @param {Array} allValues
 * @returns {$.application.cards.card}
 */
function getNextCard(card, allValues) {
    var divCard = $('div[id="' + card.getId() + '"].card');

    if (card.getType() === card.TYPE_WIZARD) {
        var next = card.getNext();
        if (next.length > 0) {
            card = $.application.cards.getById(next[0]);
            if (card) {
                card = getNextCard(card, allValues);
            } else {
                $('.step_menu .button .next', divCard).addClass('loading');
            }
        }
    }

    if (card.getCondition() !== '' && card.getConditionNext() !== '') {
        var formula = new Formula(prepareFormula(card.getCondition(), allValues), allValues);
        formula.setSymbols(Condition.getSymbols());
        var result = formula.evaluate();
        if (!result) {
            card = $.application.cards.getById(card.getConditionNext());
            if (card) {
                card = getNextCard(card, allValues);
            } else {
                $('.step_menu .button .next', divCard).addClass('loading');
            }
        }
    }

    return card;
}

/**
 * Ukaze danou kartu dle id karty
 * @param {integer} idCard
 */
function showCard(idCard) {
    $('.card').hide();
    var card = $('div[id="' + idCard + '"].card');
    card.show();

    var prevIndex = $.application.list.getPreviousIndex(idCard);
    if (prevIndex === null) {
        $('.button_previous', card).trigger('disable');
    }
    else {
        $('.button_previous', card).trigger('enable');
    }
}

function addValueHistory(body, label, value) {
    body += '<div class="option">';
    body += label;
    body += '<span class="value">' + (value === true ? 'Ano' : (value === false ? 'Ne' : value)) + '</span>';
    body += '</div>';
    return body;
}

/**
 * Aktualizuje historii
 * @param {Array} previousValues
 */
function updateHistory(previousValues) {
    var body = '<div class="history">';
    for (var index = 0; index < $.application.list.getAllItems().length; index++) {
        var card = $.application.list.getItem(index);
        var divCard = $('div[id="' + card.getId() + '"].card');
        var values = card.getValues();
        var variables = $.extend({}, previousValues, values);

        var isTest = getURLParameter('test');
        var closeSumm = getURLParameter('close_summ');

        body += '<div class="button" onClick="showCard(' + card.getId() + ')">';
        body += '<div class="item">';
        body += '<div class="caption">';
        body += '<div class="name-card">';
        body += $('.card_title', divCard).html();
        body += '</div>';

        if (card.getType() == card.TYPE_PARAMETERS) {   // parametry
            //console.log(card.visibleParameters);
            $.each(card.getVisibleParameters(), function (id, parameter) {
                var control = $('div[id="' + parameter.getId() + '"].control');
                var input = $('input, select, textarea', control);
                var label = $('label', control).html();
                var type = $(input).attr('type');
                var value = '';

                if (parameter.getTypeDisplay() == 1 || (parameter.getTypeDisplay() == 3 && (isTest || closeSumm)) || (parameter.getTypeDisplay() == 5 && (isTest || closeSumm))) {
                    if (type === 'radio') {
                        var checked = $(control).find('input:checked');
                        if (checked.length > 0) {
                            value = $(checked).parent().children('label').html();
                        }
                    } else if (type === 'checkbox') {
                        value = values[id] ? 'Ano' : 'Ne';
                    } else {
                        value = values[id];
                    }

                    if (value === '') {
                        return;
                    }

                    body += '<div>';
                    body = addValueHistory(body, label, value);
                    body += '</div>';
                }
            });
        } else if (card.getType() == card.TYPE_OPTIONS) {    // rozcestnik
            //console.log(card.visibleOptions);
            $.each(card.getVisibleOptions(), function (id, option) {
                var control = $('div[id="' + option.getId() + '"].control');
                var input = $('input, select, textarea', control);
                var label = $('.option-text', control).html();
                var type = $(input).attr('type');
                var value = '';

                body += '<div>';
                body = addValueHistory(body, label, value);
                body += '</div>';

                option.getParameters().forEach(function (parameter, i) {
                    if (parameter.getTypeDisplay() == 1 || (parameter.getTypeDisplay() == 3 && (isTest || closeSumm)) || (parameter.getTypeDisplay() == 5 && (isTest || closeSumm))) {
                        var formula = new Formula(prepareFormula(parameter.getCalculation(), $.extend({}, variables, values)), $.extend({}, variables, values));
                        formula.setSymbols(Calculation.getSymbols());
                        var result = formula.evaluate();
                        if (result) {
                            value = result === true ? 'Ano' : (result === false ? 'Ne' : result);
                        } else {
                            value = parameter.getCalculation() === 'true' ? 'Ano' : (parameter.getCalculation() === 'false' ? 'Ne' : parameter.getCalculation());
                        }

                        body += '<div>';
                        body = addValueHistory(body, parameter.getName(), value);
                        body += '</div>';
                    }
                });
            });
        } else if (card.getType() == card.TYPE_GROUPS) {    // komponenty
            //console.log(card.visibleGroups);
            $.each(card.getVisibleGroups(), function (id, group) {
                var control = $('div[id="' + group.getId() + '"].control');
                var input = $('input, select, textarea', control);
                var type = $(input).attr('type');

                body += '<div>';

                $.each(values[card.GROUPS_PREFIX + group.getId()], function (i, value) {
                    var labelInput = $('input[name="cards[' + card.getId() + '][groups][' + group.getId() + '][' + i + ']"]', control).parents('.list-item').children('label').html();
                    var valueInput = value;
                    labelInput = labelInput.replace(/:$/, '');
                    if (valueInput === 'true') {
                        valueInput = '';
                    }
                    else if (valueInput == 0) {
                        return;
                    }
                    body = addValueHistory(body, labelInput, valueInput);
                });

                body += '</div>';
            });
        }

        body += '</div>';
        body += '</div>';
        body += '</div>';
    }
    body += '</div>';

    $('.nav-history').html(body);
}

function getHistory() {
    return $('.history').html();
}

/**
 * Kontrola vyplneni, naklikani vsech zobrazenych parametru
 * @param {HTMLElement} divCard
 * @returns {undefined}
 */
function checkInputs(divCard) {
    var inputs = $(':text, select, :radio', divCard);
    var radios = {};
    var radiosChecked = {};
    inputs.filter(':radio').each(function () { // unikátní názvy prvků
        if ($(this).parents('.control').is(':visible')) {
            radios[$(this).attr('name')] = true;
        }
    });

    inputs.filter(':radio:checked').each(function () { // unikátní názvy prvků
        var control = $(this).parents('.control');
        if (control.is(':visible')) {
            if (control.hasClass('related')) {
                var related = control.data('related');
                if (related.length > 0) {
                    for (var i = 0; i < related.length; i++) {
                        var relControl = $('div[id="' + related[i] + '"].control');
                        radiosChecked['cards[' + $(divCard).attr('id') + '][parameters][' + relControl.attr('id') + '][' + relControl.data('control').parameter + ']'] = true;
                    }
                }
            } else {
                radiosChecked[$(this).attr('name')] = true;
            }
        }
    });

    var ret = true;
    // kontrola zaškrtnutí všech radio buttonů
    if (Object.keys(radios).length !== Object.keys(radiosChecked).length) {
        ret = false;
    }
    inputs.filter(':text:visible, select:visible').each(function () {
        if (($(this).val() === "" || $(this).hasClass('error-highlight'))) {
            ret = false;
        }
    });

    if (ret) {
        $('.button_next', divCard).trigger('enable');
    } else {
        $('.button_next', divCard).trigger('disable');
    }
}

/**
 * Kontrola validacnich podminek karty, vypsani chybove hlasky
 * @param {Card} card
 * @param {Array} allPreviousValues
 * @param {Array} cardValues
 * @returns {Boolean}
 */
function checkValidationConditions(card, allPreviousValues, cardValues) {
    var allValues = $.extend({}, $.application.defaultParameters, allPreviousValues);

    var success = true;
    $('.condition', card.getHtml()).each(function () {
        var condition = $(this).data('condition');
        var message = $(this).html();
        var formula = new Formula(prepareFormula(condition, $.extend({}, allValues, cardValues)), $.extend({}, allValues, cardValues));
        formula.setSymbols(Condition.getSymbols());
        var result = formula.evaluate();
        if (result) {
            $.application.flashes.add([{message: message, type: 'error'}]);
            success = false;
        }
    });

    return success;
}

/**
 * Nastavi na karte hodnoty
 * @param {Card} card
 * @param {Array} parameters
 */
function setData(card, parameters) {
    if (card.getType() === 2) { // rozcestnik
        return;
    }
    var divCard = $('div[id="' + card.getId() + '"].card');
    $('.control', divCard).each(function () {
        var value = parameters[$(this).attr('id')];
        if (typeof parameters[$(this).attr('id')] !== 'undefined') {
            $('input', this).each(function () {
                switch ($(this).attr('type')) {
                    case 'text':
                        if ($.isNumeric(value)) {
                            var format = $(this).data('number');
                            if (format == null) {
                                format = $(this).parent('div').data('number');
                            }
                            value = parseFloat(value).format(format.decimals, format.decimal_point, format.thousand_sep);
                        }
                        $(this).val(value);
                        //$(this).change();
                        break;
                    case 'radio':
                        if ($(this).val() === value) {
                            $(this).attr('checked', 'checked');
                            $(this).parent('div').addClass('selected');
                        }
                        else {
                            $(this).removeAttr('checked');
                            $(this).parent('div').removeClass('selected');
                        }
                        break;
                }
            });
            $('textarea', this).each(function () {
                $(this).val(value);
            });
            $('select', this).each(function () {
                $('option', this).each(function () {
                    if ($(this).val() === value) {
                        $(this).attr('selected', 'selected');
                    }
                    else {
                        $(this).removeAttr('selected');
                    }
                });
            });
        }
    });
}

/**
 * Najiti pozice karty v pruvodci
 * @param {Card} card
 * @returns {*}
 */
function getPosition(card) {
    var position = 0;

    if ($.application.positions.items[card.getId()]) {
        position = $.application.positions.items[card.getId()];
    }

    return ((position / $.application.positions.max) * 100).toFixed(0);
}

/**
 * Natiahne cenu zo servera
 * @param {Card} card
 */
function getRefreshedPrice(){
    var divCard = $('.card.bind');
    var card = $.application.cards.getById(divCard.attr('id'));
    $.application.ajaxPrice($('.url-price').data('href'), $.extend({}, $.application.list.getAllValues(), {
        idCard: card.getId(),
        wizards: $.application.list.getWizards()
    }), card);
}

/**
 * Zobrazi box a bud natiahne cenu alebo zvyrazni aktualizaciu
 */
function refreshPrice(){
    $('.price-box').show();
    if(!$('.approximate-price').length){
        getRefreshedPrice();
    } else {
        $('.referesh-price').css({ 'color': 'red' });
    }
}

// Tlacitka krok zpet/vpred
$.eachComponent('.card.bind .step_menu .button', function (button) {
    //console.log('tlacitko klik');
    var divCard = $(button).parents('.card');
    var card = $.application.cards.getById($(divCard).attr('id'));

    $(button).bind('disable', function () {
        $('div', this).addClass('disabled');
        $('div', this).attr('title', $('div', this).data('disable-title'));
    }).bind('enable', function () {
        $('div', this).removeClass('disabled');
        $('div', this).attr('title', $('div', this).data('title'));
    });

    // pokud karta nema predchudce, tak disablujem tlacitko zpet
    if ($.application.list.getPreviousIndex(card) === null) {
        $('.button_previous', divCard).trigger('disable');
    } else {
        $('.button_previous', divCard).trigger('enable');
    }

    $('div', button).click(function () {
        var divButton = this;

        setTimeout (function () {
            if (!$(divButton).hasClass('disabled')) {
                var divCard = $(divButton).parents('.card');
                var card = $.application.cards.getById($(divCard).attr('id'));
                var visibleCard = card;

                if ($(divButton).hasClass('previous')) {  // krok zpet

                    $.each(xhrs, function(key, value){
                        value.abort();
                    });
                    $(divCard).hide();
                    //$(divCard).hide("slide", {direction: "right"}, 1000);

                    // najdem predchozi kartu
                    var prevCard = $.application.list.getPrevious(card);
                    divCard = $('div[id="' + prevCard.getId() + '"].card');
                    //$(divCard).show();
                    $(divCard).show("slide", {direction: "right"}, 1000);



                    // pokud karta nema predchudce, tak disablujem tlacitko zpet
                    if ($.application.list.getIndex(prevCard) == null) {
                        $('.button_previous', divCard).trigger('disable');
                    }
                } else {    // krok vpred
                    if (card.getType() === card.TYPE_TARGETS) {
                        var data = $.extend({}, $.application.list.getAllValues(), {
                            idCard: card.getId(),
                            wizards: $.application.list.getWizards(),
                            email: $('.card.bind .content .recapitulation input[name="email"]').val(),
                            items: $.application.list.getAllItemsToJson()
                        });
                        if ($('.card.bind .content .recapitulation div:not(.hidden) input[name="email"]').length > 0) {
                            $.application.ajaxProducts($('.url-products').data('href'), data, card);
                        } else {
                            data = $.extend(data, {history: getHistory()});
                            $.application.ajaxCreateContract($('.url-create-contract').data('href'), data, card).complete(function () {
                                if (window.opener) {
                                    window.opener.$('[id*="dialogWizards"]').dialog('close');
                                    window.opener.$('input[name="reload"]').click();
                                    //window.close();
                                }
                            });
                        }
                    } else {
                        var allPreviousValues = $.application.list.getAllValuesFromFirstToPreviousCard(card);
                        var cardValues = card.getValues();

                        // kontrola validacnich podminek karty
                        if (!checkValidationConditions(card, allPreviousValues, cardValues)) {
                            return false;
                        }

                        var next = card.getNext();

                        // jestlize je karta typu rozcestnik, tak pujdem na kartu, kterou ma nastavena vybrana volba
                        if (card.getType() == card.TYPE_OPTIONS) {
                            var optionData = $(divCard).find('input:checked').parents('.control').data('control');
                            if (optionData) {
                                next = [optionData.next];
                            } else {
                                next = [];
                            }
                        }

                        // karta muze jit na dalsi
                        if (next.length > 0) {
                            $.each(xhrs, function(key, value){
                                value.abort();
                            });
                            card = $.application.cards.getById(next[0]);
                            if (card) {
                                card = getNextCard(card, $.extend({}, $.application.defaultParameters, allPreviousValues, cardValues));
                                if (card) {
                                    $(divCard).hide();
                                    //$(divCard).hide("slide", {direction: "left"}, 1000);

                                    divCard = $('div[id="' + card.getId() + '"].card');
                                    $('.button_previous', divCard).trigger('enable');

                                    // jestlize se nasledujici karta neshoduje s nasledujici kartou v seznamu, tak smazem vsechny nasledujici karty v seznamu
                                    var nextCard = $.application.list.getNext(visibleCard);
                                    if (nextCard !== null && nextCard.getId() !== card.getId()) {
                                        $.application.list.deleteFromCardToLast(visibleCard);
                                    }

                                    //$(divCard).show();



                                    $(divCard).show("slide", {direction: "left"}, 1000);
                                    $(divCard).trigger('init');
                                } else {
                                    $('.step_menu .button .next', divCard).addClass('loading');
                                    clickNext = true;
                                }
                            } else {
                                $('.step_menu .button .next', divCard).addClass('loading');
                                clickNext = true;
                            }
                        }
                    }
                }
                //window.scrollTo(0, 145);
            }
        }, 300);
    });

    //checkInputs(divCard);
});

function loadImage(images){
    var value = images.pop();
        var xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4 && xhr.status == 200) {
                var blob = new Blob([xhr.response], {
                    type: xhr.getResponseHeader("Content-Type")
                });
                var imgUrl = window.URL.createObjectURL(blob);
                //document.getElementById("img").src = imgUrl;
                $(value).attr('src', imgUrl);
            }
            //loadImage(images);
        }
        xhr.responseType = "arraybuffer";
        xhr.open("GET", $(value).data('url'), true);
        xhr.setRequestHeader('Cache-Control', 'max-age=3600');
        xhr.send();
        xhrs[$(value).data('url')] = xhr;
}

$.eachComponent('.card.bind', function (card) {
    $(card).bind('init', function () {
        if($('.google-analytics').length > 0 && $('.google-analytics-pages').length > 0 && $('.google-analytics-pages').data('script') != '') {
            eval($('.google-analytics-pages').data('script'));
        }

        var divCard = $(this);
        var card = $.application.cards.getById(divCard.attr('id'));

        var images = $(divCard).find('img');

        var pendingImages = [];
        $.each(images, function(key, value){
            if($(value).data('url') && $(value).is('img') && typeof xhrs[$(value).data('url')] === 'undefined'){
                pendingImages[key] = value;
            }
        });
        xhrs = [];
        $.each(pendingImages, function(key, value) {
            if(xhrs[$(value).data('url')]){
                xhrs[$(value).data('url')].abort();
            }
                var xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function() {
                    if (xhr.readyState==4 && xhr.status==200) {
                        var blob = new Blob([xhr.response], {
                            type: xhr.getResponseHeader("Content-Type")
                        });
                        var imgUrl = window.URL.createObjectURL(blob);
                        $(value).attr('src',imgUrl);
                    }
                }
                xhr.responseType = "arraybuffer";
                xhr.open("GET",$(value).data('url'),true);
                xhr.setRequestHeader('Cache-Control', 'max-age=3600');
                xhr.send();
                xhrs[$(value).data('url')] = xhr;
        });

        // kartu je potreba vlozit do seznamu
        $.application.list.insert(card);

        if (card.getType() == card.TYPE_TARGETS) {
            $('.price-box').hide();
            if (typeof $.application.xhrs[$('.url-price').data('href')] !== 'undefined') {
                $.application.xhrs[$('.url-price').data('href')].abort();
            }

            checkInputs(divCard);

            // produkty zobrazime pouze, pokud je uzivatel prihlasen
            if ($('.card.bind .content .recapitulation div.hidden input[name="email"]').length > 0) {
                $.application.ajaxProducts($('.url-products').data('href'), $.extend({}, $.application.list.getAllValues(), {
                    idCard: card.getId(),
                    wizards: $.application.list.getWizards(),
                    email: $('.card.bind .content .recapitulation input[name="email"]').val()
                }), card);
            }
        }
        else {
            $('.card_subtitle', this).html(getPosition(card) + ' %');

            //console.log('init card');
            var allPreviousValues = $.application.list.getAllValuesFromFirstToPreviousCard(card);
            card.updateValues($.extend({}, $.application.defaultParameters, allPreviousValues));
            setData(card, $.application.defaultParameters);
            checkInputs(divCard);

            // nacteni vsech nasledujicich karet
            loadNextCards(card, divCard);

            // zobrazime predbeznou cenu
            if (!$(divCard).hasClass('start_card') && !$('.approximate-price').length) {
                    refreshPrice();
            }

            // aktualizujeme historii
            updateHistory($.extend({}, $.application.defaultParameters, allPreviousValues));
        }
    });

    if ($(card).hasClass('start_card')) {
        // nacteni vsech nasledujicich karet
        //loadCards($(card));
        $(card).trigger('init');
    }

    $(card).not('.start_card').hide();
});

// Kontrola vyplneni/vybrani vsech moznosti
$.eachComponent('.card.bind .content', function (content) {
    $(':text, select, :radio, :checkbox, textarea', content).change(function (event) {
        var divCard = $(this).parents('.card');
        var card = $.application.cards.getById($(divCard).attr('id'));
        //console.log('input change');
        var allPreviousValues = $.application.list.getAllValuesFromFirstToPreviousCard(card);
        card.updateValues($.extend({}, $.application.defaultParameters, allPreviousValues));
        checkInputs(divCard);

        // predbeznou cenu pocitat pouze u karet ktere nejsou cil
        if(card.getType() != card.TYPE_TARGETS) {
            //getPrice(card);
                refreshPrice();
            $.application.list.deleteFromCardToLast(card);
        }

        updateHistory($.extend({}, $.application.defaultParameters, allPreviousValues));
    });
});

// Rozcestnik
$.eachComponent('.card.bind .content .options .item-img', function (item) {
    var card = $(item).parents('.card');
    var options = $(item).parents('.options');
    item = $(item);

    var clicktimer = null;
    item.click(function () {
        card = $(item).parents('.card');
        options = $(item).parents('.options');

        // code for both event
        $('input', options).removeAttr('checked');
        $('input', item).attr('checked', 'checked');
        $('.item-img', options).removeClass('selected');
        item.addClass('selected');
        $('input', item).change();
        $('.button_next', card).trigger('enable');

        if (clicktimer) {
            clearTimeout(clicktimer);
            clicktimer = null;
            // double click event
            $('.button_next div.next', card).click();
        } else {
            clicktimer = setTimeout (function (e) {
                clicktimer = null;
                // single click event
            }, 500);
        }
    });

    if ($('input', item).attr('checked')) {
        item.click();
    }
});

/**
 * rizeni inputu ano/ne pomoci tlacitek, oznaceni defaultnich hodnot
 */
$.eachComponent('.card.bind .content .parameters .parameter-item', function (item) {
    if ($('input:radio', item).attr('checked')) {
        $(item).addClass('selected');
    }

    if ($('input[type=checkbox]', item).attr('checked')) {
        $('.yes', item).addClass('selected');
        $('input[type=checkbox]', item).val('true');
        $('input[type=hidden]', item).val('true');
    }
    else {
        $('.no', item).addClass('selected');
        $('input[type=checkbox]', item).val('false');
        $('input[type=hidden]', item).val('false');
    }

    $('.yes', item).click(function () {
        $(this).addClass('selected');
        $('.no', item).removeClass('selected');
        $('input[type=checkbox]', item).attr('checked', 'checked');
        $('input[type=checkbox]', item).val('true');
        $('input[type=checkbox]', item).change();
        $('input[type=hidden]', item).val('true');
        $(item).change();
    });

    $('.no', item).click(function () {
        $(this).addClass('selected');
        $('.yes', item).removeClass('selected');
        $('input[type=checkbox]', item).removeAttr('checked');
        $('input[type=checkbox]', item).val('false');
        $('input[type=checkbox]', item).change();
        $('input[type=hidden]', item).val('false');
        $(item).change();
    });

    $('.yes, .no', item).mouseenter(function () {
        $(this).addClass('hover');
    });

    $('.yes, .no', item).mouseleave(function () {
        $(this).removeClass('hover');
    });
});

/**
 *
 */
$.eachComponent('.card.bind .content .parameters .control', function (item) {
    var card = $(item).parents('.card');
    item = $(item);

    $('.parameter-item label', item).click(function (event) {
        event.preventDefault();
    });

    $('.parameter-item', item).click(function (event) {
        event.stopPropagation();
        if ($(':radio', item).length > 0) {
            $('input', item).removeAttr('checked');
            $('input', this).attr('checked', 'checked');
            $('.parameter-item', item).removeClass('selected');
            $(this).addClass('selected');

            $('input', this).change();
        }
    });

    //if ($('input', item).attr('checked')) {
    //    $(this).change();
    //}
});

/**
 * rizeni souvisejicich parametru - zatrzeni jen jednoho z nich
 */
$.eachComponent('.card.bind .content .parameters .control.related', function (item) {
    var card = $(item).parents('.card');
    var idCard = $(card).attr('id');
    item = $(item);
    $('.parameter-item', item).click(function (event) {
        event.stopPropagation();
        if ($(':radio', item).length > 0) {
            var related = $(item).data('related');
            if (related.length > 1) {
                if ($.inArray(parseInt(item.attr('id')), related) !== -1) {
                    for (var i = 0; i < related.length; i++) {
                        $('input[name*="cards[' + idCard + '][parameters][' + related[i] + ']"]', card).parent('.parameter-item').removeClass('selected');
                        $('input[name*="cards[' + idCard + '][parameters][' + related[i] + ']"]', card).removeAttr('checked');
                    }
                }
            }
            $('input', item).removeAttr('checked');
            $('input', this).attr('checked', 'checked');
            $('.parameter-item', item).removeClass('selected');
            $(this).addClass('selected');
        }
    });

    //if ($('input', item).attr('checked')) {
    //    $(this).change();
    //}
});


// Komponenty
$.eachComponent('.card.bind .content .groups', function (context) {
    $('.list', context).mouseenter(function () {
        if ($(this).find('.list-item').is(':hidden')) {
            $(this).addClass('hover');
        }
    });

    $('.list', context).mouseleave(function () {
        $(this).removeClass('hover');
    });

    // zakomentovani skryvani/rozkryvani polozek
//    $('.list', context).find('.list-items').each(function() {
//        $(this).hide();
//    });

    // pouze jedna uroven zanoreni
//    $('.list', context).click(function() {
//        var add = $(this).children('.add').length;
//        var remove = $(this).children('.remove').length;
//        if(add) {
//            $(this).children('.add').removeClass('add').addClass('remove');
//            $(this).removeClass('hover');
//            $(this).find('.list-items').each(function() {
//                $(this).slideDown("slow");
//            });
//        }
//        else if(remove) {
//            $(this).children('.remove').removeClass('remove').addClass('add');
//            $(this).addClass('hover');
//            $(this).find('.list-items').each(function() {
//                $(this).slideUp("slow");
//            });
//        }
//    });

    $('.list-item', context).each(function () {
        var children = $(this).find('.icon-20-checkbox');
        var checkbox = $(this).find('input[type=checkbox]');

        if (checkbox.attr('checked')) {
            children.removeClass('unchecked');
            children.addClass('checked');
        }

        if (children.hasClass('checked')) {
            checkbox.attr('checked', 'checked');
            checkbox.val(true);
        }
        else {
            checkbox.removeAttr('checked');
            checkbox.val(false);
        }
    });

    $('.list-item label', context).click(function (event) {
        event.preventDefault();
    });

    $('.list-item', context).click(function (event) {
        //event.stopPropagation();
        $(this).find('input').focus();

        var list = $(this).parent('.list');
        var control = list.parent('.control');
        var select = control.data('control').select;
        var children = $(this).find('.icon-20-checkbox');
        var checkbox = $(this).find('input[type=checkbox]');

        if (select != 2 && children.hasClass('unchecked')) {
            list.find('.icon-20-checkbox').removeClass('checked').addClass('unchecked');
        }

        if (children.hasClass('unchecked')) {
            children.removeClass('unchecked');
            children.addClass('checked');
            checkbox.attr('checked', 'checked');
            checkbox.val(true);
        }
        else {
            children.removeClass('checked');
            children.addClass('unchecked');
            checkbox.removeAttr('checked');
            checkbox.val(false);
        }

        $(checkbox).change();
    });

    $('.list-item', context).mouseenter(function () {
        $(this).find('input').addClass('hover');
    });

    $('.list-item', context).mouseleave(function () {
        $(this).find('input').removeClass('hover');
    });
});

$.eachComponent('.card.bind .recapitulation', function(context) {
    $('.resultsDebugLink', context).click(function() {
        $(this).hide();
        $('.resultsDebug', context).show();
    });
});

$.eachComponent('body', function (context) {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 145) {
            $('.button', context).addClass('fixed');
        }
        else {
            $('.button', context).removeClass('fixed');
        }
    })
});