/**
 * JS pro kontaktni informace
 */
$.eachComponent('.contact-information-form',function(context){
    $('.send input:submit', context).click(function() {
        if($('.samerealaddress input:checkbox', context).is(':checked')) {
            $('.realization_address_name input', context).val($('.address_name input', context).val());
            $('.realization_address_street input', context).val($('.address_street input', context).val());
            $('.realization_address_city input', context).val($('.address_city input', context).val());
            $('.realization_address_zipcode input', context).val($('.address_zipcode input', context).val());
            $('.realization_address_phone input', context).val($('.address_phone input', context).val());
            $('.realization_address_email input', context).val($('.address_email input', context).val());
        }
    });
});

$.eachComponent('.legal_person', function(context){
    $('input:checkbox', context).change(function() {
        if($(this).is(':checked')) {
            $('.address_ic input', $(context).parents('.contact-information-form')).addClass('required');
            $('.address_ic input', $(context).parents('.contact-information-form')).attr('required', 'required');
        }
        else {
            $('.address_ic input', $(context).parents('.contact-information-form')).removeClass('required');
            $('.address_ic input', $(context).parents('.contact-information-form')).removeAttr('required');
        }
    });
    $('input:checkbox', context).change();
});

$.eachComponent('.samerealaddress', function(context){
    $('input:checkbox', context).change(function() {
        if($(this).is(':checked')) {
            $('.realization-address', $(context).parents('.contact-information-form')).hide();
        }
        else {
            $('.realization-address', $(context).parents('.contact-information-form')).show();
        }
    });
    $('input:checkbox', context).change();
});