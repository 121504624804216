/**
 * Karta
 * @param {number} id - id karty
 * @param {Object} data - json data
 * @param {string} html - html obsah karty
 * @constructor
 */
var Card = function (id, data, html) {

    this.GROUPS_PREFIX = 'c_';
    this.OPTIONS_PREFIX = 'o_';

    this.TYPE_PARAMETERS = 1;
    this.TYPE_OPTIONS = 2;
    this.TYPE_GROUPS = 3;
    this.TYPE_TARGETS = 4;
    this.TYPE_WIZARD = 5;
    /**
     * Id
     * @type {number}
     */
    this.id = id;

    /**
     * Cislo karty
     * @type {string}
     */
    this.number = typeof data !== 'undefined' ? data.number : '';

    /**
     * Nasledujici karty
     * @type {Array}
     */
    this.next = typeof data !== 'undefined' ? data.next : [];

    /**
     * Podminka zobrazeni
     * @type {string}
     */
    this.condition = typeof data !== 'undefined' ? data.condition : '';

    /**
     * Cislo nasledujici karty, pokud podminka zobrazeni neni splnena
     * @type {string}
     */
    this.conditionNext = typeof data !== 'undefined' ? data.conditionNext : '';

    /**
     * Typ karty
     * @type {number}
     */
    this.type = typeof data !== 'undefined' ? data.type : this.TYPE_PARAMETERS;

    /**
     * Id pruvodce
     * @type {number}
     */
    this.wizard = typeof data !== 'undefined' ? data.wizard : null;

    /**
     * Pozice karty
     * @type {number}
     */
    this.position = typeof data !== 'undefined' ? data.position : 0;

    /**
     * Vsechny nasledujici karty, vcetne tech na ktere se muzem dostat pri nesplneni podminek zobrazeni
     * @type {Array}
     */
    this.nexts = typeof data !== 'undefined' ? data.nexts : [];

    /**
     * Parametry karty
     * @type {Parameter[]}
     */
    this.parameters = [];

    /**
     * Viditelne parametry karty
     * @type {Parameter[]}
     */
    this.visibleParameters = {};

    /**
     * Volby rozcestniku
     * @type {Option[]}
     */
    this.options = [];

    /**
     * Viditelne volby rozcestniku karty
     * @type {Option[]}
     */
    this.visibleOptions = {};

    /**
     * Komponenty
     * @type {Group[]}
     */
    this.groups = [];

    /**
     * Viditelne komponenty karty
     * @type {Group[]}
     */
    this.visibleGroups = {};

    /**
     * Html obsah karty
     * @type {string}
     */
    this.html = typeof html !== 'undefined' ? html : '';

    /**
     * Hodnoty inputu na karte
     * @type {Object}
     */
    this.values = {};

    /**
     * @param {Parameter} parameter
     */
    this.addParameter = function (parameter) {
        this.parameters.push(parameter);
    };

    /**
     * @param {Option} option
     */
    this.addOption = function (option) {
        this.options.push(option);
    };

    /**
     * @param {Group} group
     */
    this.addGroup = function (group) {
        this.groups.push(group);
    };

    // Inicializace voleb na karte
    var card = this;
    if (this.type == this.TYPE_PARAMETERS) {
        $('.control', html).each(function() {
            $('.unit-wraper', this).trigger('unit-init');
            card.addParameter(new Parameter($(this).attr('id'), $(this).data('control'), this));
        });
    } else if (this.type == this.TYPE_OPTIONS) {
        $('.control', html).each(function() {
            card.addOption(new Option($(this).attr('id'), $(this).data('control'), this));
        });
    } else if (this.type == this.TYPE_GROUPS) {
        $('.control', html).each(function() {
            card.addGroup(new Group($(this).attr('id'), $(this).data('control'), this));
        });
    }

};

/**
 * Vrati id
 * @returns {number}
 */
Card.prototype.getId = function () {
    return this.id;
};

/**
 * Nastavi id
 * @param {number} id
 */
Card.prototype.setId = function (id) {
    this.id = id;
};

/**
 * Vrati cislo karty
 * @returns {string}
 */
Card.prototype.getNumber = function () {
    return this.number;
};

/**
 * Nastavi cislo karty
 * @returns {string} number
 */
Card.prototype.setNumber = function (number) {
    this.number = number;
};

/**
 * Vrati pole id nasledujicich karet
 * @returns {Array}
 */
Card.prototype.getNext = function () {
    return this.next;
};

/**
 * Nastavi pole id nasledujicich karet
 * @returns {Array} next
 */
Card.prototype.setNext = function (next) {
    this.next = next;
};

/**
 * Vrati podminku zobrazeni
 * @returns {string}
 */
Card.prototype.getCondition = function () {
    return this.condition;
};

/**
 * Nastavi podminku zobrazeni
 * @returns {string} condition
 */
Card.prototype.setCondition = function (condition) {
    this.condition = condition;
};

/**
 * Vrati id nasledujici karty, jestlize podminka zobrazeni neni splnena
 * @returns {string}
 */
Card.prototype.getConditionNext = function () {
    return this.conditionNext;
};

/**
 * Nastavi id nasledujici karty, jestlize podminka zobrazeni neni splnena
 * @returns {string} conditionNext
 */
Card.prototype.setConditionNext = function (conditionNext) {
    this.conditionNext = conditionNext;
};

/**
 * Vrati typ karty
 * @returns {number}
 */
Card.prototype.getType = function () {
    return this.type;
};

/**
 * Nastavi typ karty
 * @returns {number} type
 */
Card.prototype.setType = function (type) {
    this.type = type;
};

/**
 * Vrati cislo pruvodce
 * @returns {number}
 */
Card.prototype.getWizard = function () {
    return this.wizard;
};

/**
 * Nastavi cislo pruvodce
 * @returns {number} wizard
 */
Card.prototype.setWizard = function (wizard) {
    this.wizard = wizard;
};

/**
 * Vrati pozici karty
 * @returns {number}
 */
Card.prototype.getPosition = function () {
    return this.position;
};

/**
 * Nastavi pozici karty
 * @returns {number} position
 */
Card.prototype.setPosition = function (position) {
    this.position = position;
};

/**
 * Vrati vsechny nasledujici karty, vc. tech na ktere muzem skocit po nesplneni podminek zobrazeni
 * @returns {Array}
 */
Card.prototype.getNexts = function () {
    return this.nexts;
};

/**
 * Nastavi vsechny nasledujici karty, vc. tech na ktere muzem skocit po nesplneni podminek zobrazeni
 * @returns {Array} nexts
 */
Card.prototype.setNexts = function (nexts) {
    this.nexts = nexts;
};

/**
 * Vrati parameter dle id
 * @param id
 * @returns {Parameter}
 */
Card.prototype.getParameter = function (id) {
    for (var index = 0; index < this.parameters.length; index++) {
        if (id == this.parameters[index].getId()) {
            return this.parameters[index];
        }
    }

    return null;
};

/**
 * Vrati viditelny parameter dle id
 * @param id
 * @returns {Parameter}
 */
Card.prototype.getVisibleParameter = function (id) {
    return typeof this.visibleParameters[id] === 'undefined' ? null : this.visibleParameters[id];
};

/**
 * Vrati parametry na karte
 * @returns {Parameter[]}
 */
Card.prototype.getParameters = function () {
    return this.parameters;
};

/**
 * Vrati viditelne parametry na karte
 * @returns {Parameter[]}
 */
Card.prototype.getVisibleParameters = function () {
    return this.visibleParameters;
};

/**
 * Vrati volbu rozcestniku dle id
 * @param id
 * @returns {Option}
 */
Card.prototype.getOption = function (id) {
    for (var index = 0; index < this.options.length; index++) {
        if (id == this.options[index].getId()) {
            return this.options[index];
        }
    }

    return null;
};

/**
 * Vrati viditelnou volbu rozcestniku dle id
 * @param id
 * @returns {Option}
 */
Card.prototype.getVisibleOption = function (id) {
    return typeof this.visibleOptions[id] === 'undefined' ? null : this.visibleOptions[id];
};

/**
 * Vrati volby rozcestniku na karte
 * @returns {Option[]}
 */
Card.prototype.getOptions = function () {
    return this.options;
};

/**
 * Vrati viditelne volby rozcestniku na karte
 * @returns {Option[]}
 */
Card.prototype.getVisibleOptions = function () {
    return this.visibleOptions;
};

/**
 * Vrati komponentu dle id
 * @param id
 * @returns {Group}
 */
Card.prototype.getGroup = function (id) {
    for (var index = 0; index < this.groups.length; index++) {
        if (id == this.groups[index].getId()) {
            return this.groups[index];
        }
    }

    return null;
};

/**
 * Vrati viditelnou komponentu dle id
 * @param id
 * @returns {Group}
 */
Card.prototype.getVisibleGroup = function (id) {
    return typeof this.visibleGroups[id] === 'undefined' ? null : this.visibleGroups[id];
};

/**
 * Vrati komponenty na karte
 * @returns {Group[]}
 */
Card.prototype.getGroups = function () {
    return this.groups;
};

/**
 * Vrati viditelne komponenty na karte
 * @returns {Group[]}
 */
Card.prototype.getVisibleGroups = function () {
    return this.visibleGroups;
};

/**
 * Vrati html obsah karty
 * @returns {string}
 */
Card.prototype.getHtml = function () {
    return this.html;
};

/**
 * Nastavi html obsah karty
 * @returns {string}
 */
Card.prototype.setHtml = function (html) {
    this.html = html;
};

/**
 * Vrati hodnoty vsech inputu na karte
 * @returns {Array}
 */
Card.prototype.getValues = function () {
    return this.values;
};

/**
 * Nastavi hodnoty inputu na karte
 * @param {Array} values
 */
Card.prototype.setValues = function (values) {
    this.values = values;
};

/**
 * Updatne hodnoty vsech inputu na karte
 * @param {Array} previousValues
 * @returns {Array}
 */
Card.prototype.updateValues = function (previousValues) {
    previousValues = (typeof previousValues === 'undefined') ? {} : previousValues;
    var values = {};
    var card = this;

    if (this.type == this.TYPE_PARAMETERS) {   // parametry
        card.visibleParameters = {};
        this.parameters.forEach(function(parameter) {
            var id = parameter.getParameter();
            var control = $('div[id="' + parameter.getId() + '"].control', $('div[id="' + card.getId() + '"].card'));
            var input = $('input:last, select, textarea', control);
            var type = $(input).attr('type');

            var variables = $.extend({}, previousValues, values);

            if (checkCondition(parameter.getCondition(), variables)) {
                if (parameter.getCalculation() !== '') {
                    var formula = new Formula(prepareFormula(parameter.getCalculation(), variables), variables);
                    formula.setSymbols(Calculation.getSymbols());
                    var calculation = formula.evaluate();
                    if (calculation !== null && calculation !== 'NaN') {
                        $(input).val(calculation);
                    }
                }

                if (type === 'radio') {
                    var checked = $(control).find('input:checked');
                    if (checked.length > 0) {
                        values[id] = $(checked).val();
                    }
                } else if (type === 'checkbox') {
                    var checked = $(control).find('input:checked');
                    if (checked.length > 0) {
                        values[id] = true;
                    } else {
                        values[id] = false;
                    }
                } else {
                    if ($(input).val() !== '' && $(input).val() !== 'NaN') {
                        values[id] = $(input).val();
                    }
                }

                card.visibleParameters[id] = parameter;

                $(control).show();
                $(control).prev('h2').show();
            } else {
                $(control).hide();
                $(control).prev('h2').hide();
            }
        });
    } else if (this.type == this.TYPE_OPTIONS) {    // rozcestnik
        card.visibleOptions = {};
        this.options.forEach(function(option) {
            var id = card.OPTIONS_PREFIX + option.getId();
            var control = $('div[id="' + option.getId() + '"].control', $('div[id="' + card.getId() + '"].card'));
            var input = $('input, select, textarea', control);
            var type = $(input).attr('type');

            var variables = $.extend({}, previousValues, values);

            if (checkCondition(option.getCondition(), variables)) {
                var checked = $(control).find('input:checked');
                if (checked.length > 0) {
                    values[id] = $(checked).val();

                    option.getParameters().forEach(function(parameter, i) {
                        if (parameter.getType() == parameter.TYPE_SELECTION) {
                            values[i] = parameter.getValue();
                        } else {
                            var formula = new Formula(prepareFormula(parameter.getCalculation(), $.extend({}, variables, values)), $.extend({}, variables, values));
                            formula.setSymbols(Calculation.getSymbols());
                            var result = formula.evaluate();
                            if (result) {
                                values[i] = result;
                            } else {
                                values[i] = parameter.getValue();
                            }
                        }
                    });

                    card.visibleOptions[option.getId()] = option;
                }

                $(control).show();
                $(control).prev('h2').show();
            } else {
                $(control).hide();
                $(control).prev('h2').hide();
            }
        });
    } else if (this.type == this.TYPE_GROUPS) {    // komponenty
        card.visibleGroups = {};
        this.groups.forEach(function(group) {
            var id = card.GROUPS_PREFIX + group.getId();
            var control = $('div[id="' + group.getId() + '"].control', $('div[id="' + card.getId() + '"].card'));
            var input = $('input, select, textarea', control);
            var type = $(input).attr('type');

            var variables = $.extend({}, previousValues, values);

            var regex = /cards\[\d+\]\[groups\]\[\d+\]\[(\d+)\]/gi;
            var match = [];
            var value  = {};

            if (checkCondition(group.getCondition(), variables)) {
                if (type === 'radio' || type === 'checkbox') {
                    var checked = $(control).find('input:checked');
                }

                if (type === 'radio') {
                    if (checked.length > 0) {
                        values[id] = $(checked).val();
                    }
                } else if (type === 'checkbox') {
                    for (var n = 0; n < checked.length; n++) {
                        match = regex.exec($(checked[n]).attr('name'));
                        value[match[1]] = $(checked[n]).val();
                        values[id] = $.extend({}, values[id], value);
                    }
                } else {
                    match = regex.exec($(input).attr('name'));
                    value[match[1]] = Number($(input).val());
                    values[id] = $.extend({}, values[id], value);
                }

                card.visibleGroups[group.getId()] = group;

                $(control).show();
                $(control).prev('h2').show();
            } else {
                $(control).hide();
                $(control).prev('h2').hide();
            }
        });
    }

    this.values = values;

    return values;
};

/**
 * Replace ve vypoctu, podmince, ... zadanej produkt za jeho cenu
 * @param {string} formula
 * @param {Array} parameters
 * @returns {string}
 */
function replaceProductsPrices(formula, parameters) {
    var productsPrices = $.application.productsPrices;

    var productsPattern = Formula.getProductsPattern();
    formula = formula.replaceCallback(productsPattern, function (product) {
        product = product.match(productsPattern);
        product = product[1];
        var resultValue = 0;
        if (typeof(productsPrices[product]) != 'undefined' && productsPrices[product] !== null && productsPrices[product].length > 0) {
            for (var i = 0; i < productsPrices[product].length; i++) {
                var price = productsPrices[product][i];
                var resultValue1 = 0;
                var resultValue2 = 0;
                if (price['parameter_1'] != null || price['parameter_2'] != null) { // ceny v tabulce
                    if (price['parameter_1'] != null && typeof(parameters[price['parameter_1']]) != 'undefined' && parameters[price['parameter_1']] !== null) { // sloupce tabulky
                        if (price['type_1'] != null) {  // hodnota dana vypoctem
                            var isFounded1 = false;
                            var paramValue1 = 0;
                            var lastValue1 = 0;
                            for (var j = 0; j < productsPrices[product].length; j++) {  // najiti vyhovujici hodnoty soucastky
                                var parameter1 = productsPrices[product][j];
                                parameter1['value_1'] = Number(parameter1['value_1']);
                                if (parameters[price['parameter_1']] <= parameter1['value_1']) {
                                    isFounded1 = true;
                                    paramValue1 = parameter1['value_1'];
                                    break;
                                } else {
                                    isFounded1 = false;
                                    lastValue1 = parameter1['value_1'];
                                }
                            }
                            if (isFounded1 == false) {
                                paramValue1 = lastValue1;
                            }

                            if (price['min_1'] <= parameters[price['parameter_1']] && parameters[price['parameter_1']] <= price['max_1'] && paramValue1 == price['value_1']) {  // musi se vejit od zadaneho rozsahu
                                resultValue1 = price['price'];
                            }
                        } else if(price['value_1'] == parameters[price['parameter_1']]) {   // fixni hodnota (napr. vyber)
                            resultValue1 = price['price'];
                        }
                    }

                    if (price['parameter_2'] != null && typeof(parameters[price['parameter_2']]) != 'undefined' && parameters[price['parameter_2']] !== null) { // radky tabulky
                        if (price['type_2'] != null) {  // hodnota dana vypoctem
                            var isFounded2 = false;
                            var paramValue2 = 0;
                            var lastValue2 = 0;
                            for (var k = 0; k < productsPrices[product].length; k++) {  // najiti vyhovujici hodnoty soucastky
                                var parameter2 = productsPrices[product][k];
                                parameter2['value_2'] = Number(parameter2['value_2']);
                                if (parameters[price['parameter_2']] <= parameter2['value_2']) {
                                    isFounded2 = true;
                                    paramValue2 = parameter2['value_2'];
                                    break;
                                } else {
                                    isFounded2 = false;
                                    lastValue2 = parameter2['value_2'];
                                }
                            }
                            if (isFounded2 == false) {
                                paramValue2 = lastValue2;
                            }

                            if (price['min_2'] <= parameters[price['parameter_2']] && parameters[price['parameter_2']] <= price['max_2'] && paramValue2 == price['value_2']) {  // musi se vejit od zadaneho rozsahu
                                resultValue2 = price['price'];
                            }
                        } else if(price['value_2'] == parameters[price['parameter_2']]) {   // fixni hodnota (napr. vyber)
                            resultValue2 = price['price'];
                        }
                    }
                } else {    // cena bez tabulky
                    resultValue1 = price['price'];
                }

                // pokud se jedna o tabulku, musi odpovidat obe hodnoty (kombinace radek/sloupec), jinak staci jedna hodnota
                if ((price['parameter_1'] == null || resultValue1 > 0) && (price['parameter_2'] == null || resultValue2 > 0)) {
                    if (resultValue1 > 0) {
                        resultValue = resultValue1;
                    } else {
                        resultValue = resultValue2;
                    }
                    break;
                }
            }
        }

        return resultValue;
    });

    return formula;
}

function replaceTableParameters(formula, parameters) {
    var tableParameters = $.application.tableParameters;

    var variablesPattern = Formula.getVariablesPattern();
    formula = formula.replaceCallback(variablesPattern, function (parameter) {
        var param = parameter.match(variablesPattern);
        param = param[1];

        if (typeof(tableParameters[param]) != 'undefined' && tableParameters[param] !== null && !$.isEmptyObject(tableParameters[param])) {
            var tableParameter = tableParameters[param];
            var resultY = 0;
            var resultX = 0;
            var resultValue = 0;

            if (tableParameter['parameter_1'] != null || tableParameter['parameter_2'] != null) { // ceny v tabulce
                if (tableParameter['parameter_1'] != null && typeof(parameters[tableParameter['parameter_1']]) != 'undefined' && parameters[tableParameter['parameter_1']] !== null) { // sloupce tabulky
                    if (tableParameter['type_1'] == 2) { // vyber
                        for (var j in tableParameter['colsDescription']) {
                            if(!tableParameter['colsDescription'].hasOwnProperty(j)) continue;

                            if (parameters[tableParameter['parameter_1']] == tableParameter['colsDescription'][j]) {
                                resultX = j;
                                break;
                            }
                        }
                    } else {
                        var isFoundedX = false;
                        var lastX = 0;
                        for (var j in tableParameter['colsDescription']) {
                            if(!tableParameter['colsDescription'].hasOwnProperty(j)) continue;

                            if (Number(parameters[tableParameter['parameter_1']]) <= Number(tableParameter['colsDescription'][j])) {
                                isFoundedX = true;
                                resultX = j;
                                break;
                            } else {
                                isFoundedX = false;
                                lastX = j;
                            }
                        }
                        if (isFoundedX == false) {
                            resultX = lastX;
                        }
                    }
                }

                if (tableParameter['parameter_2'] != null && typeof(parameters[tableParameter['parameter_2']]) != 'undefined' && parameters[tableParameter['parameter_2']] !== null) { // radky tabulky
                    if (tableParameter['type_2'] == 2) { // vyber
                        for (var i in tableParameter['rowsDescription']) {
                            if(!tableParameter['rowsDescription'].hasOwnProperty(i)) continue;

                            if (parameters[tableParameter['parameter_2']] == tableParameter['rowsDescription'][i]) {
                                resultY = i;
                                break;
                            }
                        }
                    } else {
                        var isFoundedY = false;
                        var lastY = 0;
                        for (var i in tableParameter['rowsDescription']) {
                            if(!tableParameter['rowsDescription'].hasOwnProperty(i)) continue;

                            if (Number(parameters[tableParameter['parameter_2']]) <= Number(tableParameter['rowsDescription'][i])) {
                                isFoundedY = true;
                                resultY = i;
                                break;
                            } else {
                                isFoundedY = false;
                                lastY = i;
                            }
                        }
                        if (isFoundedY == false) {
                            resultY = lastY;
                        }
                    }
                }

                // pokud se jedna o tabulku, musi odpovidat obe hodnoty (kombinace radek/sloupec)
                if (resultY > 0 && resultX > 0) {
                    resultValue = tableParameter['matrix'][resultY][resultX]['value'];
                }
            }

            return resultValue;
        }

        return parameter;
    });

    return formula;
}

/**
 * Predpripraveni vzorce
 * @param {string} formula
 * @param {Array} variables
 */
function prepareFormula(formula, variables) {
    formula = replaceTableParameters(formula, variables);
    if (Formula.getProductsPattern().test(formula)) {
        formula = replaceProductsPrices(formula, variables);
    }

    return formula;
}