$.eachComponent('.contract', function(context){
	$('input[name="payments"]', context).change(function() {
		$('.submit_pay', context).hide();
		$('#submits-'+$(this).val()).show();
	});
	
	$('input[name="payments"]:checked', context).change();
    
    if($('.show-new-conditions', context).data('show-new-conditions') > 0) {
        var href = addUrlParam('do', 'confirmNewConditions');
        $.application.ajax(href);
    }
});