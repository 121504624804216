$.eachComponent('.summarization',function(context) {
    $('.new-wizard', context).click(function(event) {
        if($('.links', context).is(':visible')) {
            $('.links', context).fadeOut("slow");
        }
        else {
            $('.links', context).fadeIn("slow");
        }
    });
    
    $('.link', context).dblclick(function(event) {
        var href = addUrlParam('do', 'openWizard');
        $.application.ajax(href, {contract_id: $(this).data('contract-id'), calculation_id: $(this).data('calculation-id')});
    });
    
    $('.history .button', context).click(function(event) {
        var href = addUrlParam('do', 'openWizardCard');
        var link = $(this).parents('.link');
        $.application.ajax(href, {contract_id: link.data('contract-id'), calculation_id: link.data('calculation-id'), card: $(this).data('number')});
    });
    
    $(context).on('click','.new-wizard',function(event){
        event.stopPropagation();
    });
});

$.eachComponent('.dialog-choose-variant', function(context) {
    $('tbody tr', context).click(function(event) {
        if($('input:checkbox', this).is(':checked')) {
            $('input:checkbox', this).removeAttr('checked');
            $(this).removeClass('selected');
        }
        else {
            $('input:checkbox', this).attr('checked', 'checked');
            $(this).addClass('selected');
        }
        $('input:checkbox', this).change();
    });
    
    $('input:checkbox', context).bind('click change', function(event) {
        event.stopPropagation();
        if($(this).is(':checked')) {
            $(this).parents('tr').addClass('selected');
        }
        else {
            $(this).parents('tr').removeClass('selected');
        }
        
        if($($('input:checkbox:checked', context)).size() > 0) {
            $('.submit.next_small', context).removeAttr('disabled');
        }
        else {
            $('.submit.next_small', context).attr('disabled', 'disabled');
        }
    });
    
    $('input:checkbox', context).change();
});