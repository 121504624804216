/**
 * Seznam projitych karet
 * @constructor
 */
function List () {

    /**
     * Prvni index seznamu
     * @type {number}
     */
    this.firstIndex = 0;

    /**
     * Posledni index seznamu
     * @type {number}
     */
    this.lastIndex = 0;

    /**
     * Pole karet seznamu
     * @type {Card[]}
     */
    this.items = [];

    /**
     * Pridani karty do seznamu
     * @param {Card} card
     */
    this.insert = function (card) {
        if (this.exists(card)) {
            this.items[this.getIndex(card)] = card;
        } else {
            this.items.push(card);
            this.lastIndex = this.items.length - 1;
        }
    };

    /**
     * Existuje jiz karta v seznamu?
     * @param {Card} card
     * @returns {boolean}
     */
    this.exists = function (card) {
        return this.getIndex(card) ? true : false;
    };

    /**
     * Vrati predchozi kartu
     * @param {Card} card
     * @returns {Card|null}
     */
    this.getPrevious = function (card) {
        var prevIndex = this.getPreviousIndex(card);

        return prevIndex >= 0 ? this.getItem(prevIndex) : null;
    };

    /**
     * Vrati kartu dle indexu
     * @param {number} index
     * @returns {Card}
     */
    this.getItem = function (index) {
        return this.items[index];
    };

    /**
     * Vrati index predchozi karty
     * @param {Card} card
     * @returns {number|null}
     */
    this.getPreviousIndex = function (card) {
        var actIndex = this.getIndex(card);
        var prevIndex = actIndex - 1;

        return prevIndex >= 0 ? prevIndex : null;
    };

    /**
     * Vrati index karty
     * @param {Card} card
     * @returns {number|null}
     */
    this.getIndex = function (card) {
        for (var index = 0; index < this.items.length; index++) {
            if (this.items[index].getId() == card.getId()) {
                return index;
            }
        }

        return null;
    };

    /**
     * Vrati vsechna data vsech prvku od prvni karty po posledni kartu
     * @returns {Array}
     */
    this.getAllValues = function () {
        var allValues = {};
        for (var index = 0; index < this.items.length; index++) {
            $.extend(allValues, this.items[index].getValues());
        }

        return allValues;
    };

    /**
     * Vrati vsechny karty v seznamu
     * @returns {Array}
     */
    this.getAllItems = function () {
        return this.items;
    };

    /**
     * Vrati data vsech karet v seznamu jako pole
     * @returns {Array}
     */
    this.getAllItemsToJson = function () {
        var array = [];
        for (var index = 0; index < this.items.length; index++) {
            array.push({
                idCard: this.items[index].getId(),
                idWizard: this.items[index].getWizard(),
                value: this.items[index].getValues()
            });
        }

        return array;
    };

    this.setAllItemsFromJson = function (array) {
        for (var index = 0; index < array.length; index++) {
            var card = new Card(array[index].idCard);
            card.setWizard(array[index].idWizard);
            card.setValues(array[index].value);
            this.insert(card);
        }
    };

    /**
     * Vrati vsechna data vsech prvku od prvni karty po zadanou kartu
     * @param {Card} card
     * @returns {Array}
     */
    this.getAllValuesFromFirstToPreviousCard = function (card) {
        var allValues = {};
        var actIndex = this.getIndex(card);
        if (actIndex !== null) {
            for (var index = 0; index < actIndex; index++) {
                $.extend(allValues, this.items[index].getValues());
            }
        }

        return allValues;
    };

    /**
     * Najiti nasledujici karty
     * @param card
     * @returns {Card|null}
     */
    this.getNext = function (card) {
        var actIndex = this.getIndex(card);
        if (typeof this.items[actIndex + 1] !== 'undefined') {
            return this.items[actIndex + 1];
        }
        else {
            return null;
        }
    };

    /**
     * Rusi prvky seznamu od zadane karty po posledni
     * @param {Card} card
     */
    this.deleteFromCardToLast = function (card) {
        var actIndex = this.getIndex(card);
        if (actIndex !== null) {
            while (actIndex != this.getLastIndex()) {
                this.items.splice(this.getLastIndex(), 1);
                this.lastIndex = this.getLastIndex();
            }
        }
    };

    /**
     * Vrati id vsech pruvodcu
     * @returns {Array}
     */
    this.getWizards = function () {
        var wizards = [];
        for (var index = 0; index < this.items.length; index++) {
            if ($.inArray(this.items[index].getWizard(), wizards) < 0 && $.isNumeric(this.items[index].getWizard())) {
                wizards.push(this.items[index].getWizard());
            }
        }

        return wizards;
    };

    /**
     * Vrati index posledniho karty
     * @returns {number}
     */
    this.getLastIndex = function () {
        return this.items.length - 1;
    };

}