/**
 * @author Lukas Brzobohaty
 */

(function ($) {

    $.extend({
        application: {
            cards: new Cards(),     // karty
            defaultParameters: [],  // vychozi parametry
            positions: {
                min: 0,
                max: 0,
                items: {}
            },          // pozice karet v pruvodci
            list: new List(),       // seznam karet
            productsPrices: [],     // ceny produktu
            tableParameters: [],     // parametry typu tabulka
            ajaxSuccess: function (payload) {
                var app = $.application;

                // state
                if (payload.state) {
                    app.state = payload.state;
                }

                // title
                if (payload.title) {
                    document.title = payload.title.parts.join(payload.title.separator).split(payload.title.separator).reverse().join(payload.title.separator);
                    payload.title.parts.shift();
                    $('#content-title').html(payload.title.parts.join(payload.title.separator));
                }

                // redirect
                if (payload.redirect && payload.redirect.url) {
                    payload.redirect.url = payload.redirect.url.replace(/https?:/, location.protocol);
                    if (!payload.redirect.noajax) {
                        $.application.ajax(payload.redirect.url, {}, null, true);
                    }
                    else {
                        location.href = payload.redirect.url;
                    }
                }

                // dialog
                if (payload.dialog) {
                    app.handleDialogs(payload.dialog);
                }

                // snippets
                if (payload.snippets) {
                    for (var selector in payload.snippets) {
                        $("#" + selector).html(payload.snippets[selector]);
                    }
                }

                // flashes
                if (payload.flashes) {
                    app.flashes.add(payload.flashes);
                }

                // debugBar (laděnka)
                if (payload.debugBar) {
                    var debug = $('#tracy-debug').size() > 0 ? $('#tracy-debug') : $('<div></div>').attr('id', 'tracy-debug').addClass('noajax').appendTo('body');
                    debug.html(unescape(payload.debugBar));
                    for (var i = 0, scripts = debug[0].getElementsByTagName('script'); i < scripts.length; i++) {
                        (window.execScript || function(data) {
                            window['eval'].call(window, data);
                        })(scripts[i].innerHTML);
                    }
                    Tracy.Dumper.init();
                    Tracy.Debug.init();
                    debug[0].style.display = 'block';
                }
                else {
                    $('#tracy-debug').remove();
                }
            },
            // inicializace aplikace
            init: function () {
                var app = $.application;

                $('#tracy-debug').addClass('noajax');
                $('<div></div>').attr('id', 'arrowmess').appendTo('body');

                app.flashes.init();

                app.addEvents();

                if ($('.default-parameters').length > 0) {
                    this.defaultParameters = $('.default-parameters').data('default-parameters');
                }
                if ($('.products-prices').length > 0) {
                    this.productsPrices = $('.products-prices').data('products-prices');
                }
                if ($('.table-parameters').length > 0) {
                    this.tableParameters = $('.table-parameters').data('parameters');
                }
                if ($('.data-list-items').length > 0) {
                    this.list.setAllItemsFromJson($('.data-list-items').data('list-items'));
                }

                // nacteme pozice karet a zjistime pozici posledni karty
                if ($('.positions').length > 0) {
                    var max = 0;
                    $.each($('.positions').data('positions'), function (key, value) {
                        if (value > max) {
                            max = value;
                        }
                    });

                    this.positions = {
                        min: 0,
                        max: max,
                        items: $('.positions').data('positions')
                    };
                }

                // nacteni prvni karty
                if ($('.card.loading').length > 0) {
                    app.ajaxLoadCard($('.url-load-card').data('href'), null, true);
                }

                // pokud je nastavenej parametr test, tak vyhodime alert ze jde o testovaciho pruvodce
                if (getURLParameter('test') === 'true') {
                    alert($('.test-alert').html());
                }
            },
            ajax: function (url, data, card, toHistory) {
                //for (var i in data) {
                //    data[i] = data[i] === 'true' ? true : (data[i] === 'false' ? false : data[i]);
                //}

                // na pozadí běží požadavek na stejnou url
                if ($.application.xhrs[url]) {
                    $.application.xhrs[url].abort(); // zabití předchozího požadavku
                }

                //$.extend(data, {json: JSON.stringify(data)});

                if (getURLParameter('test')) {
                    $.extend(data, {test: getURLParameter('test')});
                }
                if (getURLParameter('close_summ')) {
                    $.extend(data, {close_summ: getURLParameter('close_summ')});
                }
                if (getURLParameter('close_target')) {
                    $.extend(data, {close_target: getURLParameter('close_target')});
                }

                $.application.xhrs[url] = $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    dataType: "json",
                    cache: false,
                    beforeSend: function () {
                        $('body').addClass('application-loading');
                    },
                    success: function (payload) {
                        $.application.ajaxSuccess(payload);

                        $.eachComponent('reload');

                        if(toHistory === true && !/&do=/.test(url) && !/\?do=/.test(url) && !/\/print\//.test(url) && history.pushState) { // jen pokud nejde o signál (...&do=...) nebo tisk (.../print/...)
                            history.pushState({
                                href: url
                            }, '', url);
                        }
                    },
                    complete: function () {
                        delete $.application.xhrs[url]; // odstranění url z fronty po dokončení
                        $('body').removeClass('application-loading');
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if (thrownError !== "abort" && xhr.status !== 0) {
                            $.application.flashes.add({
                                'message': 'Error ' + xhr.status + ': ' + thrownError,
                                'type': 'error'
                            });
                        }
                    }
                });

                return $.application.xhrs[url];
            },
            ajaxLoadCard: function(url, id, first) {
                first = (typeof first === 'undefined') ? false : first;

                var data = {id: id};

                //$.extend(data, {json: JSON.stringify(data)});

                if (getURLParameter('test')) {
                    $.extend(data, {test: getURLParameter('test')});
                }
                if (getURLParameter('close_summ')) {
                    $.extend(data, {close_summ: getURLParameter('close_summ')});
                }
                if (getURLParameter('close_target')) {
                    $.extend(data, {close_target: getURLParameter('close_target')});
                }

                $.application.xhrs[id] = $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    dataType: "html",
                    cache: false,
                    beforeSend: function () {
                        $('body').addClass('application-loading');
                    },
                    success: function (data) {
                        if (first) {
                            $('.card.loading').remove();
                        }

                        $('.cards').append(data);

                        var card = new Card($(data).attr('id'), $(data).data('card'), data);
                        $.application.cards.insert(card);

                        $.eachComponent('reload');
                    },
                    complete: function () {
                        delete $.application.xhrs[id]; // odstranění url z fronty po dokončení
                        $('body').removeClass('application-loading');
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if (thrownError !== "abort" && xhr.status !== 0) {
                            console.log(thrownError);

                            if (first) {
                                $('.card.loading .card_title').html('Při přípravě průvodce došlo k chybě');
                                $('.card.loading .loading').remove();
                            } else {
                                $.application.flashes.add('Při přípravě následující karty došlo k chybě');
                            }
                        }

                    }
                });

                return $.application.xhrs[id];
            },
            ajaxPrice: function(url, data, card) {
                for (var i in data) {
                    data[i] = data[i] === 'true' ? true : (data[i] === 'false' ? false : data[i]);
                }

                // na pozadí běží požadavek na stejnou url
                if ($.application.xhrs[url]) {
                    $.application.xhrs[url].abort(); // zabití předchozího požadavku
                }

                //$.extend(data, {json: JSON.stringify(data)});

                if (getURLParameter('test')) {
                    $.extend(data, {test: getURLParameter('test')});
                }
                if (getURLParameter('close_summ')) {
                    $.extend(data, {close_summ: getURLParameter('close_summ')});
                }
                if (getURLParameter('close_target')) {
                    $.extend(data, {close_target: getURLParameter('close_target')});
                }

                $.application.xhrs[url] =  $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    dataType: "html",
                    cache: false,
                    beforeSend: function () {
                        $('body').addClass('application-loading');
                        $('.price-box .approximate-price .price').empty().addClass('loading');
                    },
                    success: function (data) {
                        $('.price-box').html(data);
                        $.eachComponent('reload');
                    },
                    complete: function () {
                        delete $.application.xhrs[url]; // odstranění url z fronty po dokončení
                        $('body').removeClass('application-loading');
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if (thrownError !== "abort" && xhr.status !== 0) {
                            console.log(thrownError);
                            $.application.flashes.add({
                                'message': 'Při výpočtu předběžné ceny došlo k chybě',
                                'type': 'error'
                            });
                        }

                    }
                });

                return $.application.xhrs[url];
            },
            ajaxProducts: function(url, data, card) {
                for (var i in data) {
                    data[i] = data[i] === 'true' ? true : (data[i] === 'false' ? false : data[i]);
                }

                // na pozadí běží požadavek na stejnou url
                if ($.application.xhrs[url]) {
                    $.application.xhrs[url].abort(); // zabití předchozího požadavku
                }

                //$.extend(data, {json: JSON.stringify(data)});

                if (getURLParameter('test')) {
                    $.extend(data, {test: getURLParameter('test')});
                }
                if (getURLParameter('close_summ')) {
                    $.extend(data, {close_summ: getURLParameter('close_summ')});
                }
                if (getURLParameter('close_target')) {
                    $.extend(data, {close_target: getURLParameter('close_target')});
                }

                var divCard = $('#' + card.getId());

                var pollingPeriod = 700;
                var progressInterval;

                $.application.xhrs[url] =  $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    dataType: "html",
                    cache: false,
                    beforeSend: function () {
                        NProgress.start();

                        progressInterval = setInterval(function() {
                            if (NProgress.status <= 0.6) {
                                NProgress.inc(0.1);
                            } else if (NProgress.status <= 0.8) {
                                NProgress.inc(0.05);
                            } else {
                                NProgress.inc(0.02);
                            }

                            $('.recapitulation .progress', divCard).html(Math.round(NProgress.status * 100) + ' %');
                        }, pollingPeriod);

                        $('.recapitulation', divCard).html('<div class="loading"></div><div class="progress">0 %</div>');
                        $('.button_next', divCard).trigger('disable');
                        $('body').addClass('application-loading');
                    },
                    success: function (data) {
                        $('.content', divCard).html(data);
                        $.eachComponent('reload');
                        $('.button_next', divCard).trigger('enable');
                    },
                    complete: function () {
                        delete $.application.xhrs[url]; // odstranění url z fronty po dokončení
                        $('body').removeClass('application-loading');
                        NProgress.done();
                        clearInterval(progressInterval);
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if (thrownError !== "abort" && xhr.status !== 0) {
                            $('.card.loading .card_title', divCard).html('Při výpočtu výsledku došlo k chybě');
                            $('.card.loading .loading', divCard).remove();

                            console.log(thrownError);
                            $.application.flashes.add({
                                'message': 'Při výpočtu výsledku došlo k chybě',
                                'type': 'error'
                            });
                        }

                    }
                });

                return $.application.xhrs[url];
            },
            ajaxCreateContract: function (url, data, card) {
                for (var i in data) {
                    data[i] = data[i] === 'true' ? true : (data[i] === 'false' ? false : data[i]);
                }

                // na pozadí běží požadavek na stejnou url
                if ($.application.xhrs[url]) {
                    $.application.xhrs[url].abort(); // zabití předchozího požadavku
                }

                if (getURLParameter('test')) {
                    $.extend(data, {test: getURLParameter('test')});
                }
                if (getURLParameter('close_summ')) {
                    $.extend(data, {close_summ: getURLParameter('close_summ')});
                }
                if (getURLParameter('close_target')) {
                    $.extend(data, {close_target: getURLParameter('close_target')});
                }

                var divCard = $('#' + card.getId());

                $.application.xhrs[url] = $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    dataType: "json",
                    cache: false,
                    beforeSend: function () {
                        $('.step_menu .button .next', divCard).addClass('loading');
                        $('body').addClass('application-loading');
                    },
                    success: function (payload) {
                        $.application.ajaxSuccess(payload);

                        $.eachComponent('reload');
                    },
                    complete: function () {
                        delete $.application.xhrs[url]; // odstranění url z fronty po dokončení
                        $('.step_menu .button .next', divCard).removeClass('loading');
                        $('body').removeClass('application-loading');
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if (thrownError !== "abort" && xhr.status !== 0) {
                            console.log(thrownError);
                            $.application.flashes.add({
                                'message': 'Při dokončení průvodce došlo k chybě',
                                'type': 'error'
                            });
                        }
                    }
                });

                return $.application.xhrs[url];
            },
            ajaxForm: function (submit, url, data) {
                // na pozadí běží požadavek na stejnou url
                if ($.application.xhrs[url]) {
                    $.application.xhrs[url].abort(); // zabití předchozího požadavku
                }

                $.application.xhrs[url] = $.ajax({
                    type: "POST",
                    url: url,
                    data: data,
                    cache: false,
                    contentType: false,
                    processData: false,
                    beforeSend: function () {
                        $('body').addClass('application-loading');
                        $(submit).addClass('disabled');
                    },
                    success: function (payload) {
                        $.application.ajaxSuccess(payload);
                        $.eachComponent('reload');
                    },
                    complete: function () {
                        delete $.application.xhrs[url]; // odstranění url z fronty po dokončení
                        $('body').removeClass('application-loading');
                        $(submit).removeClass('disabled');
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        if (thrownError != "abort" && xhr.status !== 0) {
                            $.application.flashes.add({
                                'message': 'Error ' + xhr.status + ': ' + thrownError,
                                'type': 'error'
                            });
                        }
                    }
                });

                return $.application.xhrs[url];
            },
            addEvents: function () {
                var app = $.application;

                if (!!(window.history && history.state)) {
                    window.onpopstate = function () {
                        app.ajax(document.location.href);
                    }
                }

                $('a:not(.noajax)').live('click', function (e) {
                    // žádný rodič není .noajax a prvek má atribut href
                    if ($(this).parents('.noajax').size() <= 0 && $(this).attr('href') !== undefined && !/^javascript:/.test($(this).attr('href'))) {
                        e.preventDefault();
                        app.ajax(this.href, {}, null, true);
                    }
                });

                // odeslání kliknutím na tlačítko
                $('form:not(.noajax) :submit').live('click', function (e) {
                    e.preventDefault();
                    e.stopPropagation();
                    if ($(this).is('.dialog-close')) {
                        $(this).parents('.ui-dialog-content:first').dialog('close');
                    }
                    else {
                        var form = $(this).parents('form:first');
                        if ($(this).is('.noajax')) { // noajax - vola klacisky submit nad formem
                            if ($(this).is('.targetBlank')) {
                                form.attr('target', '_blank');
                            }
                            form.trigger('submit', {
                                get: '&' + escape($(this).attr('name')) + '=' + escape($(this).val()),
                                noajax: true,
                                event: e
                            });
                        }
                        else { // odesalni formu ajaxem
                            var formData = new FormData();
                            formData.append($(this).attr('name'), $(this).val());

                            var other_data = form.serializeArray();
                            $.each(other_data,function(key, input){
                                formData.append(input.name, input.value);
                            });

                            app.ajaxForm($(this), form.attr("action"), formData);
                        }
                    }
                });

                // odeslání kliknutím na tlačítko
                $('form.noajax :submit').live('click', function (e) {
                    if ($(this).is('.dialog-close')) {
                        e.preventDefault();
                        e.stopPropagation();
                        $(this).parents('.ui-dialog-content:first').dialog('close');
                    }
                });
            },
            handleDialogs: function (dialogsSettings) {
                var app = $.application;

                for (var id in dialogsSettings) {
                    var dialog;
                    if (app.dialogs[id]) {   // dialog už existuje
                        dialog = app.dialogs[id];
                    }
                    else {  // dialog neexistuje, je třeba ho vytvořit
                        dialog = $('<div></div>').attr('id', id);
                        app.dialogs[id] = dialog;
                        dialog.dialog({
                            width: 285,
                            minHeight: 90,
                            dialogClass: 'container_48',
                            autoOpen: false,
                            hide: {
                                effect: 'drop',
                                direction: "down"
                            },
                            modal: true,
                            resizable: false,
                            close: function (event, ui) {
                                $('#arrowmess').empty(); /* odstranění tooltipů při zavření dialogu */
                            }
                        });

                    }

                    // nastavení všech vlastností dialogu
                    for (var option in dialogsSettings[id]) {
                        dialog.dialog('option', option, dialogsSettings[id][option]);
                    }

                    // akce s dialogem
                    switch (dialogsSettings[id].action) {
                        case 'open':
                            dialog.dialog('open');
                            break;
                        case 'close':
                            if (dialog.is(':visible')) {
                                dialog.dialog('close');
                            }
                            break;
                    }
                }
            },
            // Flash zprávy zobrazované nahoře na stránce
            flashes: {
                marginTop: 10, // odsazení zprávy od horní hrany okna
                delay: 8000, // čas po který je jedna zpráva zobrazena
                enabled: true, // zda je povoleno zobrazovat fashes
                messages: [], // fronta zpráv
                timeout: null, // objekt s timeoutem při volání funkce hide
                disable: function () {
                    $.application.flashes.enabled = false;
                }, // zakázání zobrazování fashes
                enable: function () {
                    $.application.flashes.enabled = true;
                }, // povolení zobrazování fashes
                init: function () {
                    if ($('.message-box').size() !== 1) {
                        return;
                    }
                    $('.message-box').show();
                    var top = $('.message-box').offset().top - parseFloat($('.message-box').css('marginTop').replace(/auto/, 0)) - $.application.flashes.marginTop;
                    var orig_width = $('.message-box').width();
                    var orig_left = ($('.message-box').parent().outerWidth() - orig_width) / 2;
                    var orig_top = parseFloat($('.message-box').css('top'));
                    $('.message-box').hide();

                    $(window).scroll(function (event) {
                        // what the y position of the scroll is
                        var y = $(this).scrollTop();
                        // whether that's below the form
                        if (y >= top) {
                            // if so, ad the fixed class
                            $('.message-box').css({
                                'position': 'fixed',
                                'left': ((document.body.offsetWidth - orig_width) / 2),
                                'top': $.application.flashes.marginTop
                            });
                        } else {
                            // otherwise remove it
                            $('.message-box').css({
                                'position': 'absolute',
                                'left': orig_left,
                                'top': orig_top
                            });
                        }
                    });

                    // Přidání zpráv předaných atributem
                    $.application.flashes.add($.parseJSON($('.message-box').attr('data-flashes')));
                },
                add: function (msg) { // přidání nové zprávy
                    var msgs = $.application.flashes.messages;
                    if (isArray(msg)) {
                        //for(var i in msg) {
                        msg.each(function (i) {
                            msgs.push(msg[i]);
                        });
                    }
                    else {
                        msgs.push(msg);
                    }

                    $.application.flashes.show();
                },
                get: function () {
                    return $.application.flashes.messages.shift();
                }, // vrácení první následující zprávy v pořadí
                show: function () { // zobrazení nové zprávy
                    var flashes = $.application.flashes;
                    if (flashes.enabled) {
                        var messageBox = $('.message-box');
                        var messageBoxContent = $('.message-box-content', messageBox);

                        var msg = flashes.get();
                        if (msg) {
                            messageBox.removeClass('error info success warning');

                            messageBox.addClass(msg.type);
                            messageBoxContent.html(msg.message);

                            messageBox.fadeIn();
                            clearTimeout(flashes.timeout);
                            flashes.timeout = setTimeout(flashes.hide, flashes.delay);
                        }
                    }
                },
                hide: function () { // skrytí zprávy po určitém timeoutu
                    var messageBox = $('.message-box');
                    messageBox.fadeOut();
                    $.application.flashes.show();
                }
            },
            arrowMessage: {
                show: function (element, type, message) {
                    $('#arrowmess').empty();
                    type = (typeof type === 'undefined') ? 'error' : type;
                    message = (typeof message === 'undefined') ? '' : message;
                    var pos = $(element).offset();
                    var width = $(element).outerWidth();
                    var finetooltip = $('<div class="arrowmess ' + type + '"><div class="arrowmess-arrow"></div><div class="arrowmess-body"><div>' + message + '</div></div></div>').appendTo('#arrowmess');
                    finetooltip.css({
                        top: (pos.top - 6),
                        left: (pos.left + width + 10),
                        whiteSpace: 'nowrap'
                    });
                    finetooltip.show();
                    if ($('.arrowmess-body', finetooltip).width() > 250) {
                        finetooltip.css({
                            width: 250,
                            whiteSpace: 'normal'
                        });
                    }
                },
                hide: function () {
                    $('#arrowmess').empty();
                }
            },
            state: {},
            dialogs: [],
            xhrs: {}
        }
    });

})(jQuery);