/**
 * Pole vsech karet
 * @constructor
 */
function Cards () {

    /**
     * @type {Card[]}
     */
    this.items = {};

    /**
     * @param {Card} card
     */
    this.insert = function(card) {
        this.items[card.getId()] = card;
    };

    /**
     * @param id
     * @returns {Card}
     */
    this.getById = function(id) {
        return this.items[id];
    };
}