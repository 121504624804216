/**
 * Komponenta
 * @param {number} id - id
 * @param {Object} data - json data
 * @param {string} html - html obsah komponenty
 * @constructor
 */
function Group(id, data, html) {

    Base.apply(this, arguments);

    /**
     * Lze zadavat mnozstvi?
     * @type {boolean}
     */
    this.quantityOption = data.quantityOption;

    /**
     * Mnozstvi
     * @type {number}
     */
    this.quantity = data.quantity;

    /**
     * Minimalni hodnota
     * @type {number}
     */
    this.min = data.min;

    /**
     * Maximalni hodnota
     * @type {number}
     */
    this.max = data.max;

    /**
     * @type {number}
     */
    this.select = data.select;

    /**
     * Html obsah komponenty
     * @type {string}
     */
    this.html = html;
}

Group.prototype = Base.prototype;
//Group.prototype.constructor = Group;

/**
 * Lze zadavat mnozstvi?
 * @returns {boolean}
 */
Group.prototype.getQuantityOption = function () {
    return this.quantityOption;
};

/**
 * Vrati mnozstvi
 * @returns {number}
 */
Group.prototype.getQuantity = function () {
    return this.quantity;
};

/**
 * Vrati minimalni hodnotu
 * @returns {number}
 */
Group.prototype.getMin = function () {
    return this.min;
};

/**
 * Vrati maximalni hodnotu
 * @returns {number}
 */
Group.prototype.getMax = function () {
    return this.max;
};

/**
 *
 * @returns {number}
 */
Group.prototype.getSelect = function () {
    return this.select;
};