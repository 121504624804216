// CurrencyPanel
$().ready(function() {
    $('html').bind('click dblclick contextmenu', function(){
        $('#currencypanel').hide();
    });
});

$.eachComponent('header', function(context) {

    $(context).on('click','#nav-currency',function(event){
        event.stopPropagation();
        $('#currencypanel').toggle();
        $('#langpanel').hide();
        $('.sign-in-form').fadeOut("slow");
    });

});