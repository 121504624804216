/**
 * Input s kalendářem na výběr data
 */
$.eachComponent('.dateinput', function(context) {
    // Při kliknutí na ikonu se nastaví focus inputu
    $('.calendar', context).not('.disabled').click(function() {
        $('input', context).focus();
    });

    $('input', context).datepicker();

});


/**
 * Formátování čísla
 */
$.eachComponent('.input_control_number', function(context) {

    var format = $.parseJSON($(context).attr('data-number'));
    if(format == null) {
        var parent = $(context).parent('div');
        format = $.parseJSON(parent.attr('data-number'));
    }

    var allow_empty = $.parseJSON($(context).attr('data-allow_empty'));

    $(context).bind('format', function(){
        var val = $(this).val();
        var num = $(this).val().toNumber();

        //if(allow_empty && (!isEmpty(val))){
            $(this).val(num.format(format.decimals, format.decimal_point, format.thousand_sep));
        //}
    });

    $(context).change(function(e) {
        $(this).trigger('format');
    });

});

$.eachComponent('.unit-wraper', function(context) {
    var initialized = false;
    $(context).bind('unit-init', function() {
        if(!initialized && $(this).is(':visible')) {
            var unitWidth = $('.unit', context).outerWidth();
            var input = $('input', context);
            var inputWidth = input.outerWidth();
            input.css('padding-right', unitWidth + parseInt(input.css('padding-right')) - 4);
            input.outerWidth(inputWidth);
            initialized = true;
        }
    }).trigger('unit-init');
});

// Popisky a více
$.eachComponent('.description-container', function(context){
    $(context).on('click','.btn-more .more',function(event){
        var id = $(this).attr('id').replace('btn-more-','');
        $('#dialog').html($('#more-content-'+id).html());
        $('#dialog').dialog({
            modal: true,
            width: 960,
            maxHeight: 600,
            resizable: false,
            buttons: [{
                id: "button_close",
                text: $('.button-close', context).data('title'),
                click: function() {$(this).dialog("close")}
            }]
        });
    });
});

// Nápověda karty
$.eachComponent('.card-help', function(context){
    var helpDialog = $('.help-text', context).dialog({
            modal: true,
            width: 960,
            maxHeight: 600,
            autoOpen: false,
            buttons: [{
                id: "button_close",
                text: $('.button-close', context).data('title'),
                click: function() {$(this).dialog("close")}
            }]
        });
    $('.card-help-btn',context).click(function(){
        helpDialog.dialog('open');
    });
});

// Nápověda karty
$.eachComponent('.price-box .approximate-price', function(context){
    var helpPriceDialog = $('.help-text', $(context).parent()).dialog({
            modal: true,
            width: 960,
            maxHeight: 600,
            autoOpen: false,
            buttons: [{
                id: "button_close",
                text: $('.button-close', $(context).parent()).data('title'),
                click: function() {
                    $(this).dialog("close")
                }
            }]
        });
    $('.help-btn',context).click(function(){
        helpPriceDialog.dialog('open');
    });
});