/**
 * Inicializace aplikace
 * @author Jan Preuss
 */

$(document).ready(function() {
    $.application.init();
    //$.application.flashes.disable(); // pokud Tom nebude chtít falshes messages, tak odkomentovat
});

function addUrlParam(name, value) {
    var href = location.href;
    var regex = new RegExp("[&\\?]" + name + "=");
    
    if(regex.test(href)) {
        regex = new RegExp("([&\\?])" + name + "=\\d+");
        return href.replace(regex, "$1" + name + "=" + value);
    }
    else {
        if(href.indexOf("?") > -1) {
            return href + "&" + name + "=" + value;
        }
        else {
            return href + "?" + name + "=" + value;
        }
    }
}