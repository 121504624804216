/**
 * JS pro kontaktni informace
 */
$.eachComponent('.legal_person', function(context){
    $('#frmform-legal_person', context).change(function() {
        if($(this).is(':checked')) {
            $('#frmform-ic', $(context).parents('.personal-information-form')).addClass('required');
            $('#frmform-ic', $(context).parents('.personal-information-form')).attr('required', 'required');
        }
        else {
            $('#frmform-ic', $(context).parents('.personal-information-form')).removeClass('required');
            $('#frmform-ic', $(context).parents('.personal-information-form')).removeAttr('required');
        }
    });
    $('#frmform-legal_person', context).change();
});