/**
 * Objekt pro praci se vzorcem
 * @param {string} formula
 * @param {Array} variables
 * @constructor
 */
function Formula(formula, variables) {

    formula = (typeof formula === 'undefined') ? '' : formula;
    variables = (typeof variables === 'undefined') ? [] : variables;

    /**
     * Regular na najiti promennych
     * @type {RegExp}
     */
    this.variablesPattern = /\$#([^=]+?)(=[^\$]+?)?#\$/i;

    /**
     * Regular na najiti soucastek
     * @type {RegExp}
     */
    this.productsPattern = /@([^@]+)@/i;

    /**
     * Vzorec
     * @type {string}
     */
    this.formula = format(formula);

    /**
     * Pole promennych
     * @type {Array}
     */
    this.variables = variables;

    /**
     * Pole symbolu pro nahrazeni ve vzorci
     * @type {Array}
     */
    this.symbols = [];

    /**
     * Validuje zadaný vzorec
     * @returns {boolean}
     */
    this.validate = function () {
        var variables = [];
        var formula = this.replaceSymbols(this.formula);
        if (formula.match(this.variablesPattern) !== null) {
            var matches = formula.match(this.variablesPattern);
            for (var key in matches[1]) {
                variables[key] = Math.floor(Math.random() * 1000) + 1;
            }
        }
        return this.evaluate(variables) !== null;
    };

    this.evaluate = function(variables) {
        variables = (typeof variables === 'undefined') ? this.variables : variables;

        for (var i in variables) {
            if (variables[i].toString().match(/^\d+$/) || variables[i].toString().match(/^\d+\.\d+$/)) {
                variables[i] = parseFloat(variables[i]);
            } else {
                variables[i].toString().toLowerCase();
            }

            variables[i] = variables[i] === 'true' ? true : (variables[i] === 'false' ? false : (variables[i] === 'null' ? undefined : variables[i]));
        }

        var formula = this.replaceSymbols(this.formula);
        formula = this.replaceFunction(formula);

        var variablesPatern = this.variablesPattern;
        var value = formula.replaceCallback(variablesPatern, function (variable) {
            variable = variable.match(variablesPatern);
            if (!variable[2]) {
                return " variables[" + variable[1] + "]";
            }
            else {
                return " variables[" + variable[1] + "]=" + variable[2];
            }
        });

        value = value.toString().replace(/formula/g, '$.formula');

        var varResult = null;
        var result = false;

        try {
            varResult = eval(value);
            result = true;
        }
        catch (err) {
            result = false;
        }

        return result !== false ? varResult : null;
    };

    /**
     * Nahradí ve vzorci některé symboly, aby bylo možné vzorec vyhodnotit
     * @param {string} formula
     */
    function format(formula) {
        var find = ['x+', '\\\\', '\,+', '\;+'];
        var replace = ['*', '/', '.', ','];
        return formula.toString().replaceArray(find, replace);
    }

    this.replaceSymbols = function (formula, useLabels) {
        var attr = (typeof useLabels !== 'undefined') ? 'label' : 'value';
        var symbols = this.symbols;
        var variablesPatern = this.variablesPattern;
        return formula.toString().replaceCallback(variablesPatern, function (variable) {
            variable = variable.match(variablesPatern);
            if (typeof symbols[variable[1]] !== 'undefined') {
                return " " + symbols[variable[1]][attr];
            }
            return variable[0];
        });
    };

    this.replaceFunction = function (formula) {
        var delimiter = "$";
        var functionPattern = /<functionName>[a-z_]+/i;
        var ignore = ["true", "false", "null"];
        var symbol = false;
        var replace = "";
        var output = "";

        for (var i = 0; i < formula.length; i++) {
            if (formula[i] === delimiter) {
                symbol = !symbol;
            }
            if (!symbol) {
                replace += formula[i];
            }
            else {
                output += replace === "" ? formula[i] : replace.replaceCallback(functionPattern, function (m) {
                    if ($.inArray(m['functionName'].toLowerCase(), ignore)) {
                        return "$.formula.functions." + m['functionName'].toLowerCase();
                    }
                    return m['functionName'];
                }) + formula[i];
                replace = "";
            }
        }
        output += replace;
        return output;
    };

    /**
     * Nastaví používané symlboly (např. +, -, *)
     * @param {Array} symbols
     */
    this.setSymbols = function (symbols) {
        this.symbols = symbols;
    };
}

/**
 * Regular na najiti soucastky
 * @returns {RegExp}
 */
Formula.getProductsPattern = function() {
    return /@([^@]+)@/i;
};

/**
 * Regular na najiti parametru
 * @returns {RegExp}
 */
Formula.getVariablesPattern = function() {
    return /\$#([^=]+?)(=[^\$]+?)?#\$/i;
};